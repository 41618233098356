import { IUser } from '../../models';


export class WhoAmi {
  static readonly type = '[Auth] WhoAmI';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class LoggedIn {
  static readonly type = '[Auth] LoggedIn';
}

export class LoggedOut {
  static readonly type = '[Auth] LoggedOut';
}


export class AuthUpdated {
  static readonly type = '[Auth] Updated';
}

export class UpdatedProfile {
  static readonly type = '[Auth] Profile Updated';
  constructor(public payload: {user: IUser}){}
}

export class UpdatedProfileFro{
  static readonly type = '[Auth] Profile Updated';
  constructor(public payload: {first_name: string, last_name: string, email: string}){}
}
