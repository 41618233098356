<div class="input-group">
  <ng-content></ng-content>
  <span class="input-group-append">
    <button class="btn btn-discreet" type="button"
      (click)="toggle()">
      <i class="fa fa-fw mr-1"
        [ngClass]="showPassword? 'fa-eye-slash': 'fa-eye'"></i>
        <ng-container *transloco="let t">{{showPassword ? t('actions.hide') : t('actions.show')}}</ng-container>      
    </button>
  </span>
</div>
