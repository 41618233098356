import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
// import { catchError, map } from 'rxjs/operators';
import { LangService } from 'src/app/lang/lang.service';
import { IBasicUser, IDomain } from 'src/app/shared/models';
import { IStudio3ApiError, Studio3ApiError } from '../../api/studio-api-error.model';
import { StudioApiService } from '../../api/studio-api.service';
import { AddAccount } from '../core/core.actions';
import { TabsMessage } from '../tabs-bus/tabs-bus.actions';
import { AuthUpdated, LoggedIn, LoggedOut, Logout, WhoAmi, UpdatedProfileFro } from './auth.actions';
import { WhoAmIResponse } from './auth.model';

// Age threshold in (ms) before we refresh whoami info
export const AUTH_FRESHNESS = 600000; // 10 minutes


export interface AuthStateModel {
  tk?: string;
  user?: IBasicUser;
  // accounts: {
  //   [code: string]: IAccount
  // };
  accounts_count?: number;
  account_codes: string[];
  permissions: string[];
  preferences: { [pref: string]: any };
  lastUpdate: number;
  inlogin?: boolean;
  error?: IStudio3ApiError;
  domains?: IDomain[];
}

export const defaultState: AuthStateModel = {
  account_codes: [],
  permissions: [],
  preferences: {},
  lastUpdate: 0
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultState
})
@Injectable()
export class AuthState {

  constructor(
    protected api: StudioApiService,
    // protected cookieService: StudioCookieService,
    protected router: Router,
    protected zone: NgZone,
    protected lang: LangService
  ) {

  }

  @Selector()
  static getState(state: AuthStateModel): AuthStateModel {
    return state;
  }
  @Selector()
  static token(state: AuthStateModel) {
    return state.tk;
  }

  // @Selector()
  // static isAuthenticated(state: AuthStateModel): boolean {
  //   return Boolean(state && state.tk);
  // }

  // @Selector()
  // static isAnonymous(state: AuthStateModel): boolean {
  //   return Boolean(!state || !state.tk);
  // }

  @Selector()
  static getUser(state: AuthStateModel): IBasicUser {
    return state.user;
  }

  @Selector()
  static getAccountCodes(state: AuthStateModel): string[] {
    return state.account_codes || [];
  }

  @Selector()
  static getError(state: AuthStateModel): IStudio3ApiError {
    return state.error;
  }

  @Selector()
  static getPermissions(state: AuthStateModel): string[] {
    return state.permissions ? state.permissions : [];
  }

  @Selector()
  static getPermissionsAsObject(state: AuthStateModel): {
    [id: string]: boolean
  } {
    return state.permissions ? state.permissions.reduce((r, p) => ({ ...r, [p]: true }), {}) : {};
  }

  @Selector()
  static getPreferences(state: AuthStateModel): { [pref: string]: any } {
    return state.preferences ? state.preferences : {};
  }

  @Selector()
  static getDomains(state: AuthStateModel): IDomain[] {
    return state.domains ? state.domains : [];
  }

  @Selector()
  static getLastUpdate(state: AuthStateModel): number {
    return state.lastUpdate;
  }

  @Action(Logout)
  public logout(ctx: StateContext<AuthStateModel>) {
  }

  @Action(WhoAmi, {
    cancelUncompleted: true
  })
  public whoami(ctx: StateContext<AuthStateModel>) {

    // export interface WhoAmIResponse {
    //   permissions: string[];
    //   preferences: {};
    //   accounts: IBasicAccount[];
    //   accounts_count?: number;
    //   user: IBasicUser;
    //   domains?: IDomain[];
    // }
    
    const pseudoUser = {
      "accounts": [
        {
          "accountcode": "AC-1000063",
          "decommissioned": false,
          "domain_name": "",
          "flags": {},
          "is_domain": true,
          "name": "GoCo",
          "tags": [
            ""
          ]
        }
      ],
      "accounts_count": 1,
      "domains": [],
      "permissions": [],
      "preferences": {},
      "user": {
        "default_account": "AA-1000000",
        "email": "indigo@goco.ca",
        "first_name": "Indigo",
        "id": 1,
        "last_name": "Indigo"
      }
    }
    const newState = cleanWhoAmIResponse(pseudoUser);
    ctx.patchState(newState);
    return ctx.dispatch([
      new AddAccount(pseudoUser.accounts),
      new TabsMessage({
        state: 'auth',
        action: 'patch',
        data: newState
      })
    ]);
  }

  // Version prior to August 29th 2021

  // @Action(WhoAmi, {
  //   cancelUncompleted: true
  // })
  // public whoami(ctx: StateContext<AuthStateModel>) {
  //   console.log('FRO who am ');
  //   return this.api.get('/auth/whoami').pipe(
  //     catchError(e => {
  //       if (e instanceof Studio3ApiError) {
  //         throw e;
  //       }
  //       ctx.dispatch(new Logout());
  //       throw new Studio3ApiError(e);
  //     }),
  //     map((data: WhoAmIResponse) => {
  //       // this.cookieService.setAuthCookie(ctx.getState().tk);

  //       console.log("Fro WhoAmI data ", data);
  //       const newState = cleanWhoAmIResponse(data);
  //       ctx.patchState(newState);
  //       ctx.dispatch([
  //         new AddAccount(data.accounts),
  //         new TabsMessage({
  //           state: 'auth',
  //           action: 'patch',
  //           data: newState
  //         })
  //       ]);
  //     })
  //   );
  // }


  // @Action(UpdatedProfile)
  // public updatedProfile(ctx: StateContext<AuthStateModel>, {payload}: UpdatedProfile){
  //   ctx.patchState({
  //     user: payload.user,
  //     accounts_count: payload.user.accounts_count
  //   })
  // }

  @Action(UpdatedProfileFro)
  public updatedProfileFro(ctx: StateContext<AuthStateModel>, {payload}: UpdatedProfileFro){
  
    const pseudoUser = {
      "accounts": [
        {
          "accountcode": "u01",
          "decommissioned": false,
          "domain_name": "",
          "flags": {},
          "is_domain": true,
          "name": "GoCo",
          "tags": [""]
        }
      ],
      "accounts_count": 1,
      "domains": [],
      "permissions": [],
      "preferences": {},
      "user": {
        "default_account": "u01",
        "email": payload.email,
        "first_name": payload.first_name,
        "id": 1,
        "last_name": payload.last_name
      }
    }
    const newState = cleanWhoAmIResponse(pseudoUser);
    ctx.patchState(newState);
    return ctx.dispatch([
      new AddAccount(pseudoUser.accounts),
      new TabsMessage({
        state: 'auth',
        action: 'patch',
        data: newState
      })
    ]);


  }


  @Action([LoggedIn, LoggedOut])
  public reloadRoutes(ctx: StateContext<AuthStateModel>, action: LoggedIn | LoggedOut | AuthUpdated) {
    // const newState = JSON.parse(localStorage.getItem('auth'));

    // ctx.setState(newState);
    this.zone.run(() => {
      // const currentUrl = action instanceof LoggedOut this.router.url;
      Promise.resolve(null).then(() => {
        this.router.navigate(['/', this.lang.lang, 'app']);
        // console.warn(' -- GO FOR IT ', action);
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([currentUrl]);
        // });
      });
    });
  }



}

function cleanUser(u: IBasicUser): IBasicUser {
  if (!u.default_account && (u as any).master_accountcode) {
    u.default_account = (u as any).master_accountcode;
    delete (u as any).master_accountcode;
  }
  return u;
}


function cleanPrefs(prefs: { [pref: string]: any }): { [pref: string]: any } {
  return Object.keys(prefs).reduce((ret, p) => {

    try {
      ret[p] = JSON.parse(prefs[p]);
    } catch (e) {

    }

    return ret;
  }, prefs);
}


function cleanWhoAmIResponse(data: WhoAmIResponse): Partial<AuthStateModel> {
  const state: Partial<AuthStateModel> = {
    permissions: data.permissions,
    preferences: cleanPrefs(data.preferences),
    user: cleanUser(data.user),
    accounts_count: data.accounts_count,
    // accounts: data.accounts.reduce((ret, a) => {
    //   ret[a.code] = cleanAccount(a);
    //   return ret;
    // }, {}),
    lastUpdate: Date.now(),
    domains: data.domains || [],
    account_codes: ['u01']    // was probably
  };

  return state;

}
