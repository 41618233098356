import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IIndigoConfig, IIndigoLandingConfig } from './app-config.model';
import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';
import { merge } from 'lodash';
import { DEFAULT_INDIGO_CONFIG } from './app-config.default';

export const CONFIG_FILE_URL = 'assets/config/config.json';
export const CONFIG_API_ERROR = 'Missing api_uri in dynamic config';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  protected appConfig: IIndigoConfig;
  protected readonly ready = new ReplaySubject<boolean>();

  constructor(private http: HttpClient) {
    this.ready.next(false);
  }

  loadAppConfig() {
    return this.http
      .get(CONFIG_FILE_URL)
      .toPromise()
      .then((data: IIndigoConfig) => {
        this.appConfig = merge(DEFAULT_INDIGO_CONFIG, data);
        if (!this.appConfig.api_uri) {
          throw new Error(CONFIG_API_ERROR);
        }
        if (this.appConfig.api_uri.endsWith('/')) {
          this.appConfig.api_uri = this.appConfig.api_uri.substr(0, this.appConfig.api_uri.length - 1);
        }
        this.ready.next(true);
      });
  }

  get isReady$() { return this.ready.asObservable(); }
  getDefaultTheme() {
    return this.appConfig ? this.appConfig.theme : DEFAULT_INDIGO_CONFIG.theme;
  }

  getAppVersion() {
    return environment.version;
  }

  getDebug() {
    return this.appConfig.debug || !environment.production;
  }

  getProdMode() {
    return environment.production;
  }

  getEnvironment() {
    return this.appConfig ? this.appConfig.environment : undefined;
  }

  getApiUri() {
    return this.appConfig.api_uri;
  }

  getAppConfig(): IIndigoConfig {
    return this.appConfig;
  }
  getOIDCConf() {
    return this.appConfig.oidc;
  }

  getAuthPrefix() {
    return this.appConfig.oidc.header_prefix;
  }
  getOIDC(): OpenIdConfiguration {
    const conf = this.appConfig.oidc;
    // console.log("CONF: ", conf);
    const oidcConf: OpenIdConfiguration = {
      stsServer: conf.url,
      authWellknownEndpoint: conf.well_known_endpoint,
      redirectUrl: window.location.origin + '/login',
      postLogoutRedirectUri: window.location.origin + '/',
      clientId: conf.client_id,
      scope: conf.scope || 'openid',
      responseType: conf.response_type, // was 'code',
      logLevel: (environment.production ? LogLevel.None : LogLevel.Warn),
      storage: localStorage,
      triggerAuthorizationResultEvent: true,
      disableIatOffsetValidation: true,
      autoUserinfo: false, // was false
    };
    if (conf.well_known_endpoint) {
      oidcConf.authWellknownEndpoint = conf.well_known_endpoint;
    }
    switch (null) {// a valider
      case LogLevel.None:
      case 'None':
        oidcConf.logLevel = LogLevel.None;
        break;
      case LogLevel.Error:
      case 'Error':
        oidcConf.logLevel = LogLevel.Error;
        break;
      case LogLevel.Warn:
      case 'Warn':
        oidcConf.logLevel = LogLevel.Warn;
        break;
      case LogLevel.Debug:
      case 'Debug':
        oidcConf.logLevel = LogLevel.Debug;
        break;
    }

    return oidcConf;
  }



  getExperimentalModules(): string[] {
    return this.appConfig.experimental_modules || [];
  }

  getLandingConfig(): IIndigoLandingConfig {
    return this.appConfig.landing;
  }


}
