import { Directive, Input, OnChanges, HostBinding } from '@angular/core';

@Directive({
  selector: '[appInputPassword]'
})
export class InputPasswordDirective {

  @Input() showPassword = false;

  @HostBinding('attr.type')
  get type() {
    return this.showPassword ? 'text' : 'password';
  }
  constructor() { }




}
