import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { PERMISSIONS } from './config/permissions.const';
import { LandingComponent } from './landing/landing.component';
import { LangValidatorGuard } from './lang/lang-validator.guard';
import { LangResolver } from './lang/lang.resolver';
import { BasicMessageComponent } from './shared/components/basic-message/basic-message.component';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { PermsGuard } from './shared/guards/perms.guard';

const routes: Routes = [
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [MaintenanceGuard],
    canActivateChild: [MaintenanceGuard],
    data: {
      is_maintenance: true
    }
  },
  // {
  //   path: 'client-info',
  //   loadChildren: () => import('./private/client-info/client-info.module').then(m => m.ClientInfoModule),
  //   canActivateChild: [PermsGuard, MaintenanceGuard],
  //   outlet: 'modal',
  //   data: {
  //     permissions: PERMISSIONS.client_info_window_view
  //   }
  // },
  {
    path: 'notifications',
    loadChildren: () => import('./private/notif-sidebar/notif-sidebar.module').then(m => m.NotifSidebarModule),
    canActivateChild: [AuthenticatedGuard, MaintenanceGuard],
    outlet: 'modal',
  },
  {
    path: '',
    // redirectTo: 'fr/login',
    pathMatch: 'full',
    component: LandingComponent,
    resolve: {
      lang: LangResolver
    },
    // canActivate: [AnonymousGuard]
    // canActivate: [LangSelectorGuard],
    // component: EmptyComponent
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.IndigoAuthModule),
  },
  {
    path: ':lang',
    canActivate: [LangValidatorGuard],
    canActivateChild: [LangValidatorGuard],
    children: [
      // {
      //   path: 'signout',
      //   canActivate: [SignoutGuard],
      //   component: EmptyComponent
      // },
      // {
      //   path: '',
      //   loadChildren: () => import('./auth/auth.module').then(m => m.IndigoAuthModule),
      // },
      {
        path: 'app',
        loadChildren: () => import('./private/private.module').then(m => m.PrivateModule),
        canActivateChild: [AuthenticatedGuard, MaintenanceGuard],
      }
    ]
  },
  {
    path: '**',
    component: BasicMessageComponent,
    data: {
      basic_msg: {
        title: 'not-found.title',
        msg: 'not-found.msg',
        container_class: 'alert alert-warning',
        icon: 'fas fa-exclamation-circle pull-right'
      }
    }
    // redirectTo: 'account/' + ROUTE_ACCOUNT_PLACEHOLDER,
    // pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'corrected',
    scrollPositionRestoration: 'enabled',
    // enableTracing: !environment.production
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
