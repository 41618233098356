import { HttpClientModule, HttpParams, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthModule, EventTypes, OidcConfigService, PublicEventsService } from 'angular-auth-oidc-client';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './config/app-config.service';
import { ApiErrorInterceptor } from './errors/api-error.interceptor';
import { DummyErrorHelperService } from './errors/dummy-error-helper.service';
import { SentryErrorHandlerService } from './errors/sentry-error-handler.service';
import { SentryScopeHelperService } from './errors/sentry-scope-helper.service';
import { StudioErrorHandlerService } from './errors/studio-error-handler.service';
import { LandingComponent } from './landing/landing.component';
import { TranslocoRootModule } from './lang/transloco-root.module';
import { StudioFormsModule } from './shared/forms/forms.module';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { MaintenanceInterceptor } from './shared/services/maintenance.interceptor';
import { SharedModule } from './shared/shared.module';
import { AuthState } from './shared/state/auth/auth.state';
import { CoreState } from './shared/state/core/core.state';
// import { FlashState } from './shared/state/flash/flash.state';
import { PeriodState } from './shared/state/period/period.state';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { TabsBusState } from './shared/state/tabs-bus/tabs-bus.state';

defineLocale('fr', frLocale);

const initializerConfigFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

function configureAuth(
  oidcConfigService: OidcConfigService,
  appConfig: AppConfigService, transloco: TranslocoService) {
  return () => {
    return appConfig.isReady$.pipe(
      filter(r => r),
      map(() => oidcConfigService.withConfig(appConfig.getOIDC())),
      take(1)
    ).toPromise();
  };
}



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    TranslocoLocaleModule.init({
      langToLocaleMapping: {
        en: 'en-CA',
        fr: 'fr-CA'
      }
    }),
    CollapseModule.forRoot(),     // from ngx-bootstrap
    BsDropdownModule.forRoot(),   // from ngx-bootstrap
    TooltipModule.forRoot(),      // from ngx-bootstrap
    ModalModule.forRoot(),        // from ngx-bootstrap
    AlertModule.forRoot(),        // from ngx-bootstrap
    TabsModule.forRoot(),         // from ngx-bootstrap
    ProgressbarModule.forRoot(),  // from ngx-bootstrap
    PopoverModule.forRoot(),      // from ngx-bootstrap
    StudioFormsModule,            // from shared/forms
    VirtualScrollerModule,        // from ngx-virtual-scroller
    SharedModule,                 // tons of shared
    CollapseModule.forRoot(),     // from ngx-bootstrap
    BsDatepickerModule.forRoot(), // from ngx-bootstrap

    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      primaryColour: '#fff',
      secondaryColour: 'rgba(255,255,255,0.2)',
      backdropBackgroundColour: 'rgba(37, 39, 56, 0.2)'
    }),

    // NGXS
    NgxsModule.forRoot([
      AuthState,
      CoreState,
      // FlashState,
      PeriodState,
      TabsBusState
    ], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false
      }
    }),
    // Storage should be the first ngxs plugin
    // DISABLE WHILE NOT IN PRODUCTION AND A LOT OF CHANGES ARE HAPPENING
    NgxsStoragePluginModule.forRoot({
      key: [
        // AuthState,
        // CoreState
        // PeriodState
      ],
      storage: StorageOption.SessionStorage
    }),
    NgxsResetPluginModule.forRoot(),
    // ReduxDevTools should be last ngxs plugin
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),

    AuthModule.forRoot({
      // storage:
    })
  ],
  providers: [
    // va executer en tout premier
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [AppConfigService],
    },

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true },

    // TODO: TEST HOW THIS INTERACTS WITH OIDC
    // {
    //   provide: NGXS_PLUGINS,
    //   useValue: tabsBusPlugin,
    //   multi: true
    // },

    {
      provide: ErrorHandler,
      useClass: environment.production ? SentryErrorHandlerService : StudioErrorHandlerService,
    },
    {
      provide: 'ErrorHelper',
      useClass: environment.production ? SentryScopeHelperService : DummyErrorHelperService
    },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService, AppConfigService, TranslocoService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
      .subscribe((config) => {
        // console.log('ConfigLoaded', config);
      });
  }
}


// Fix for http params as
// https://github.com/angular/angular/issues/19071
HttpParams.prototype.toString = function() {
  // tslint:disable-next-line: variable-name
  const _this = this;
  this.init();
  return this.keys()
    // tslint:disable-next-line: only-arrow-functions
    .map(function(key) {
      let eKey = _this.encoder.encodeKey(key);
      const values = _this.map.get(key);
      if (values.length > 1) {
        eKey += '[]';
      }
      // tslint:disable-next-line: only-arrow-functions
      return values.map(function(value) { return eKey + '=' + _this.encoder.encodeValue(value); })
        .join('&');
    })
    .join('&');
};
