import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/config/app-config.service';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Studio3ApiError } from '../api';
import { StartMaintenance, StopMaintenance } from '../state/core/core.actions';
import { CoreState } from '../state/core/core.state';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  baseUri;
  ready = false;
  constructor(
    public config: AppConfigService,
    public store: Store,
  ) {
    this.config.isReady$.pipe(
      filter(r => r),
      tap((r) => { this.ready = r; this.baseUri = this.config.getApiUri(); }),
      take(1)
    ).subscribe();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.ready && request.url.startsWith(this.baseUri)) {
      return next.handle(request).pipe(
        catchError((e) => {
          if ((e instanceof Studio3ApiError && e.status === 503 && e.error === 'maintenance')
            || (e instanceof HttpErrorResponse && e.status === 503 && e.error && e.error.error === 'maintenance')) {
            this.store.dispatch(new StartMaintenance());
          }

          throw e;
        }),
        tap((data) => {
          if (data instanceof HttpResponse && data.status !== 503 && this.store.selectSnapshot(CoreState.isInMaintenance)) {
            this.store.dispatch(new StopMaintenance());
          }
        })
      );
    }
    return next.handle(request);

  }
}
