import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumberFromString, { CountryCode, NumberFormat } from 'libphonenumber-js';

@Pipe({
  name: 'phoneOrExt'
})
export class PhoneOrExtPipe implements PipeTransform {

  transform(value: string, mainNumber: string, options?: {
    country?: CountryCode,
    format?: NumberFormat
  }): string {

    try {
      const parsed = parsePhoneNumberFromString(value, options?.country || 'CA');
      if (parsed && parsed.isPossible()) {
        const format = options?.format || (parsed.countryCallingCode === '1' ? 'NATIONAL' : 'INTERNATIONAL');
        const formated = parsed.format(format);
        return formated || value;
      }
    } catch (e) {

    }

    try {
      const parsed = parsePhoneNumberFromString(mainNumber, options?.country || 'CA');
      if (parsed) {
        parsed.ext = value;
        const format = options?.format || (parsed.countryCallingCode === '1' ? 'NATIONAL' : 'INTERNATIONAL');
        const formated = parsed.format(format);
        return formated || value;
      }
    } catch (e) {

    }

    return value;
  }

}
