<div class="modal-header">
  <h5 class="modal-title " [innerHTML]="title"></h5>
  <button type="button" class="close" aria-label="Close"
    (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="description" [innerHTML]="description"></p>
  <alert [type]="alertType" *ngIf="showAlert">
    <i class="mr-2" [ngClass]="alertIcon" *ngIf="alertIcon"></i>
    <span [innerHTML]="alertText"></span>
  </alert>

  <div class="form-check" *ngIf="showCheckbox">
    <input class="form-check-input" type="checkbox" [(ngModel)]="confirmed" [attr.id]="id+'cb'">
    <label class="form-check-label" [attr.for]="id+'cb'">
      {{checkboxText}}
    </label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="modalRef.hide()" class="btn  btn-default">
    {{cancelText}}
  </button>

  <button type="button" (click)="confirm()" [disabled]="showCheckbox && !confirmed" class="btn" [ngClass]="confirmClass">
    {{confirmText}}
  </button>
</div>