import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { until } from 'protractor';
import timezones from 'timezones-list';
import { SetPeriodTimezone } from '../../state/period/period.actions';
import { PeriodState } from '../../state/period/period.state';

interface ITimezone {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
  group: string;
}

@Component({
  selector: 'app-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss']
})
export class TimezoneSelectorComponent extends OnDestroyMixin implements OnInit {

  @Input() inputClass = 'form-control';
  selected: string;
  readonly items: ITimezone[] = timezones.map(tz =>
    ({ ...tz, group: tz.tzCode.substr(0, tz.tzCode.indexOf('/')) }));

  defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  constructor(
    protected store: Store
  ) {
    super();
    this.items.sort((a, b) => {
      if (a.name > b.name){
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

  }

  ngOnInit(): void {
    this.store.select(PeriodState.getTimezone).pipe(
      untilComponentDestroyed(this)
    ).subscribe(t=> this.selected = t);
  }

  setValue(value: string){
    this.selected = value;
    this.store.dispatch(new SetPeriodTimezone(value));
  }

}
