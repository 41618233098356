<ng-container *ngIf="config$ | async as c">
  <ng-container *transloco="let t">
    <div [ngClass]="c.container_class">      
      <h2 *ngIf="c.title"  [ngClass]="c.title_class">
        <i [ngClass]="c.icon" *ngIf="c.icon" class="mr-2"></i>
        <span [innerHTML]="t(c.title)"></span>
      </h2>
      <div *ngIf="c.msg" [innerHTML]="t(c.msg)" [ngClass]="c.msg_class"></div>
    </div>
  </ng-container>
</ng-container>