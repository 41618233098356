import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { FormControlValidateDirective } from './form-control-validate/form-control-validate.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { FormGroupComponent } from './form-control-validate/form-group/form-group.component';
import { FormGroupErrorComponent } from './form-control-validate/form-group-error/form-group-error.component';

@NgModule({
  declarations: [
    ErrorComponent, FormControlValidateDirective, FormGroupComponent, FormGroupErrorComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [ErrorComponent, FormControlValidateDirective, FormGroupComponent, FormGroupErrorComponent]
})
export class StudioFormsModule { }
