import { Component, OnInit, Optional } from '@angular/core';

import { FormGroupComponentParent} from '../form-group/form-group.interface';

@Component({
  selector: 'goco-form-group-error',
  templateUrl: './form-group-error.component.html',
  styleUrls: ['./form-group-error.component.scss'],

})
export class FormGroupErrorComponent implements OnInit {

  constructor(
    @Optional() public formGroup: FormGroupComponentParent) { }

  ngOnInit(): void {
  }

}
