import { AfterContentInit, Component, ContentChildren, ElementRef, HostBinding, Input, OnInit, QueryList, forwardRef } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { DEFAULT_ERRORS } from '../../forms.const';
import { FormGroupErrorComponent } from '../form-group-error/form-group-error.component';
import { IFormGroupComponent, FormGroupComponentParent } from './form-group.interface';
@Component({
  // tslint:disable:component-selector
  selector: '.form-group',
  template: `
    <ng-content></ng-content>
    <ng-container *ngIf="!FormGroupErrorComponents.length && isDirtyAndTouched && hasErrors">
    <small
      *ngFor="let message of messages()"
      class="text-danger" [innerHTML]="message.msg | transloco:message.vars">
    </small>
    </ng-container>
  `,
  styles: ['small {display: block;}'],
  providers: [{ provide: FormGroupComponentParent, useExisting: forwardRef(() => FormGroupComponent) }]
})
export class FormGroupComponent implements IFormGroupComponent, OnInit, AfterContentInit {

  @ContentChildren(FormControlName, { descendants: true, })
  FormControlNames: QueryList<FormControlName>;

  @ContentChildren(FormGroupErrorComponent, { descendants: true, })
  FormGroupErrorComponents: QueryList<FormGroupErrorComponent>;


  // tslint:disable-next-line: no-input-rename
  @Input('formErrors')
  errorMessages: {
    [validator: string]: string
  } = {};

  @Input()
  validationDisabled = false;

  // @HostBinding('class.required')
  // get isRequired(){
  //   return this.FormControlNames.some(c => c.control.
  // }

  @HostBinding('class.has-error')
  get hasErrors() {
    return (
      !this.validationDisabled &&

      this.FormControlNames.some(c => !c.valid && c.dirty && c.touched)
    );
  }

  @HostBinding('class.has-success')
  get hasSuccess() {
    return (
      !this.validationDisabled &&
      !this.FormControlNames.some(c => !c.valid) &&
      this.FormControlNames.some(c => c.dirty && c.touched)

    );
  }



  public messages = () => this.getMessages();

  constructor(
    private elRef: ElementRef,
  ) { }

  ngAfterContentInit() {
  }

  ngOnInit() {
  }

  get label() {
    const label = this.elRef.nativeElement.querySelector('label');
    return label && label.textContent ? label.textContent.trim() : '';
  }

  get isDirtyAndTouched(): boolean {
    return this.FormControlNames.some(c => c.dirty && c.touched);
  }

  private getMessages(): { msg: string, vars: object }[] {
    const messages = [];
    if (!this.isDirtyAndTouched || this.validationDisabled) {
      return messages;
    }

    const names = this.FormControlNames.map(f => f.name);

    this.FormControlNames.filter(
      (c, i) =>
        !c.valid &&
        !!c.errors &&
        // filter out FormControlNames that share the same name - usually for radio buttons
        names.indexOf(c.name) === i
    ).forEach(control => {
      Object.keys(control.errors).forEach(key => {
        const error = this.errorMessages[key] || DEFAULT_ERRORS[key];
        if (!error) {
          return;
        }
        messages.push({ msg: error, vars: control.errors[key] });
      });
    });

    return messages;
  }
}
