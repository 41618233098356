import { InjectionToken } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

export const BOOTSTRAP_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const MAX_WIDTH_BREAKPOINTS = ['sm', 'md', 'lg', 'xl'].reduce((r, b) => {
  r[b] = '(max-width: ' + (BOOTSTRAP_BREAKPOINTS[b] - 1) + 'px)';
  return r;
}, {}) as { sm: string, md: string, lg: string, xl: string};
export const MIN_WIDTH_BREAKPOINTS = ['sm', 'md', 'lg', 'xl'].reduce((r, b) => {
  r[b] = '(min-width: ' + (BOOTSTRAP_BREAKPOINTS[b]) + 'px)';
  // console.log('BP MIN', b , r);
  return r;
}, {}) as { sm: string, md: string, lg: string, xl: string};

export const DISCREET_LOADING_CFG = {
  animationType: ngxLoadingAnimationTypes.circle,
  primaryColour: 'rgba(0,0,0,0.4)',
  secondaryColour: 'rgba(0,0,0,0.2)',
  backdropBackgroundColour: 'transparent'
};
