<ng-container *transloco="let t;">
  <header>
    <h2>{{header}} ({{count}})</h2>

    <ng-content select=".header-actions"></ng-content>
    <!-- <button type="button" class="btn btn-brand" (click)="addUser()">
      <i class="fa fa-plus mr-2"></i>
      {{t('users.add')}}
    </button> -->
  </header>

  <ng-content select=".filters">

  </ng-content>

  <div class="pagination" *ngIf="pagination">
    <div class="form-inline">
      <label>{{t('list.results_per_page')}}</label>
      <select class="custom-select w-auto ml-2" [ngModel]="query._limit"
        (ngModelChange)="changeLimit($event)">
        <option *ngFor="let s of batchSizes" [value]="s">{{s}}</option>
      </select>
    </div>

    <app-pagination [count]="count" [offset]="query._offset"
      [hidden]="count <= query._limit" [limit]="query._limit"
      (offsetChanged)="offsetChanged($event)">
    </app-pagination>
  </div>

  <ng-content select="alert"></ng-content>
  <ng-content select="goco-studio-error"></ng-content>


  <div class="loading-container">
    <ngx-loading [show]="loading"></ngx-loading>
    <ng-container *ngIf="display === 'table' else customDisplay">
      <ngx-datatable [rows]="items" [columns]="realColumns"
        class="bootstrap table-striped table-bordered table-sm"
        [columnMode]="ColumnMode.flex" [scrollbarH]="true" rowHeight="auto"
        [externalSorting]="true" (sort)="onSort($event)" [sorts]="currentSort"
        [messages]="{emptyMessage: t('list.emptyMessage')}"
        [virtualization]="false" [trackByProp]="trackByProp">

        <ng-content select="ngx-datatable-row-detail"></ng-content>
        <ng-content select="ngx-datatable-column"></ng-content>

      </ngx-datatable>
    </ng-container>
    <ng-template #customDisplay>
      <ng-container *ngIf="!loading && count  === 0">
        <div *ngIf="!customEmpty else customEmpty" class="text-center p-4">
          {{t('list.emptyMessage')}}</div>        
      </ng-container>
      <ng-content select=".custom-list"></ng-content>
    </ng-template>
  </div>

  <div class="pagination bottom-pagination" *ngIf="pagination">
    <app-pagination [count]="count" [offset]="query._offset"
      [hidden]="count <= query._limit" [limit]="query._limit"
      (offsetChanged)="offsetChanged($event)">
    </app-pagination>
  </div>


</ng-container>