import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth/auth.state';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IndigoAuthService } from 'src/app/auth/auth.service';
import { LangService } from 'src/app/lang/lang.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  lang = this.langService.lang;

  constructor(
    private store: Store,
    private router: Router,
    private location: Location,
    protected oidcSecurityService: OidcSecurityService,
    protected authService: IndigoAuthService,
    protected langService: LangService
  ) {
    this.langService.lang$.subscribe(l => {
      this.lang = l;
    });
  }
  isAuthenticated(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isFullyAuthenticated$.pipe(
      map((isAuthorized: boolean) => {
        if (isAuthorized) {
          return true;
        }
        let parentRoute = route;
        while (parentRoute.outlet === 'primary' && parentRoute.parent) {
          parentRoute = parentRoute.parent;
        }
        if (parentRoute.outlet !== 'primary') {
          // In a secondary outlet, just remove the outlet from the tree
          const cleanTree = this.router.parseUrl(state.url);
          delete cleanTree.root.children[parentRoute.outlet];
          return cleanTree;
        }
        this.authService.authorize(state.url, this.lang);

        return false;

      })
    );
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated(next, state);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated(next, state);
  }

}
