import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface BasicMessageConfig {
  title?: string;
  msg?: string;
  transVars?: {};
  container_class?: string;
  title_class?: string;
  icon?: string;
  msg_class?: string;
}

@Component({
  selector: 'app-basic-message',
  templateUrl: './basic-message.component.html',
  styleUrls: ['./basic-message.component.scss']
})
export class BasicMessageComponent implements OnInit {

  config$: Observable<BasicMessageConfig>;
  constructor(
    protected activatedRoute: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.config$ = this.activatedRoute.data.pipe(
      map(d => d.basic_msg || {})
    );
  }

}
