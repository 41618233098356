import { IMenuItem } from '../../../private/layout/menu/menu-item.model';
import { IBasicAccount } from '../../models';

export class SetAccount {
  static readonly type = '[Core] Set Account';
  constructor(public payload: string) { }
}

export class SetMenu {
  static readonly type = '[Core] Set Menu';
  constructor(public payload: IMenuItem[]) { }
}

export class AddAccount {
  static readonly type = '[Core] Add Account';
  constructor(public payload: IBasicAccount | IBasicAccount[]) { }
}
export class RemoveAccount {
  static readonly type = '[Core] Remove Account';
  constructor(public payload: IBasicAccount | IBasicAccount[]) { }
}

export class AccountsUpdated {
  static readonly type = '[Core] Accounts Updated';
}

export class GetAccount {
  static readonly type = '[Core] Get Account';
  constructor(public payload: { accountcode: string}) { }
}

export class StartMaintenance {
  static readonly type = '[Core] Start Maintenance';
}

export class StopMaintenance {
  static readonly type = '[Core] Stop Maintenance';
}


