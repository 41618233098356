import { HttpErrorResponse } from '@angular/common/http';
import { CATCH_ERROR_VAR } from '@angular/compiler/src/output/output_ast';
import { ErrorHandler, forwardRef, Inject, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../config/app-config.service';
import { LangService } from '../lang/lang.service';
import { Studio3ApiError, Studio3ApiUnknownError } from '../shared/api';


const sentryDsn = 'https://7c3a7a63950f49228fda6cd23a4c1797@o423479.ingest.sentry.io/5353786';


@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  debug = !environment.production;
  sentryReady = false;
  postponedEvents: any[] = [];
  ready$ = new ReplaySubject<boolean>();
  loading = false;
  constructor(
    private injector: Injector
    // @Inject(forwardRef(() => AppConfigService)) protected appConfig: AppConfigService,

    // @Inject(forwardRef(() => LangService)) protected langService: LangService
  ) {
    // this.appConfig.isReady$.pipe(
    //   filter(r => r),
    //   take(1)
    // ).subscribe(() => {
    //   this.debug = this.appConfig.getDebug();
    //   this.initSentry();
    // });
  }

  loadAll(){
    this.loading = true;
    try {
      const appConfig = this.injector.get(AppConfigService);
      const langService = this.injector.get(LangService);
      appConfig.isReady$.pipe(
          filter(r => r),
          take(1)
        ).subscribe(() => {
          this.debug = appConfig.getDebug();
          this.initSentry(appConfig, langService);
          this.loading = false;
        });
    } catch (e){
      this.loading = false;
    }
  }



  initSentry(appConfig: AppConfigService, langService: LangService) {
    if (this.sentryReady) {
      return;
    }



    Sentry.init({
      dsn: sentryDsn,
      // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
      // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
      // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
      integrations: [new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      })],

      release: 'studio3-ng@' + appConfig.getAppVersion(),
      environment: appConfig.getEnvironment()
    });

    this.sentryReady = true;




    while (this.postponedEvents.length) {
      const event = this.postponedEvents.shift();
      Sentry.captureException(event.error, event.context);
    }

    this.ready$.next(true);


    langService.lang$.subscribe(l => {
      Sentry.configureScope((scope) => {
        scope.setTag('lang', l);
      });
    });
  }

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // // Studio3ApiError
    // if (error instanceof Studio3ApiError){
    //   return error;
    // }
    if (error && error.originalError) {
      error = error.originalError;
    }
    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }



  handleError(error: any) {
    if (error && error.isSilent) {

      if (this.debug) {
        console.error('A Silent ERROR Occured (will not be thrown in production)!', error);
        throw error;
      }
      return;
    }



    const extractedError = this.extractError(error) || 'Handled unknown error';
    const context: any = { tags: {}, context: {} };
    if (error instanceof Studio3ApiError) {
      context.tags.api_error = error.error;
      context.context.error_data = error.data;
    }
    if (error instanceof Studio3ApiUnknownError){

    }
    if (!this.sentryReady && !this.loading) {
      this.loadAll();
    }
    if (this.sentryReady) {

      const eventId = Sentry.captureException(extractedError, context);
    } else {
      this.postponedEvents.push({
        error: extractedError, context,
      });
    }



    console.error('An error occured', error, extractedError);

    // Make sure to rethrow the error so Angular can pick it up
    // throw error;
  }
}
