<ng-container *transloco="let t">
  <div class="btn-group  dropdown" dropdown #dropdown="bs-dropdown"
    [autoClose]="false">
    <button class="btn text-left" dropdownToggle>

      <ng-container [ngSwitch]="selected">
        <span *ngSwitchCase="'custom'" class="custom-selected">
          <ng-container *ngIf="selectedPeriod$ | async as selectedPeriod">
            {{selectedPeriod.start | translocoDate:{ dateStyle: 'medium'} }}
            -
            {{selectedPeriod.end |  translocoDate:{ dateStyle: 'medium'} }}
          </ng-container>
        </span>
        <span *ngSwitchDefault class="">
          {{t('periods.' + selected)}}
        </span>
      </ng-container>
    </button>
    <!-- <button class="btn dropdown-toggle"
      (click)="dropdown.isOpen = !dropdown.isOpen"></button> -->
    <div class="dropdown-menu" *dropdownMenu>
      <ul>
        <ng-container *ngIf="periods as periods">
          <li role="menuitem" *ngFor="let period of periods"><a
              class="dropdown-item" [ngClass]="{'active':period === selected }"
              (click)="selectPeriod(period)">{{t('periods.' + period)}}</a>
          </li>
        </ng-container>
        <li role="menuitem">
          <a class="dropdown-item" (click)="editCustom();"
            [ngClass]="{'active':selected === 'custom'}">
            {{t('periods.custom')}}
          </a>          
        </li>
      </ul>
      <bs-daterangepicker-inline [bsValue]="customRange"
      (bsValueChange)="onRangeChange($event)"
      [hidden]="!showRangeSelector"
      
      [bsConfig]="{ isAnimated: true, maxDateRange: 30, showWeekNumbers: false, showPreviousMonth: false, dateInputFormat: 'YYYY-MM-DD', rangeInputFormat: 'YYYY-MM-DD' }">
      </bs-daterangepicker-inline>

    </div>


  </div>

</ng-container>