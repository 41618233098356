import {
  AfterViewInit, Directive, ElementRef, HostBinding,
  HostListener, Input, OnChanges, OnInit, Renderer2, RendererStyleFlags2, SimpleChanges, OnDestroy
} from '@angular/core';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { EMPTY, interval, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

// TODO ADD LEFT AND RIGHT ARROWS WITH NAVIGATION?

const SCROLL_OFFSET_FRACTION = 6;
const MARGIN = 64;
const CONTINUOUS_SCROLLING_TIMEOUT_INTERVAL = 50;
const REFRESH_INTERVAL = 800;
const ARROW_MARGIN = 24;

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[responsiveTabs]'
})
export class ResponsiveTabsDirective extends OnDestroyMixin implements OnInit, AfterViewInit, OnChanges, OnDestroy {


  padding = 0;

  margin = 4;

  listSize = 0;
  hostSize = 0;

  height = 0;
  leftPos = 0;

  list;
  scroller;

  @Input() responsiveTabs: any;

  direction = new Subject<'left' | 'right' | null>();

  protected wwidth$ = new ReplaySubject<number>();

  protected hscroll$ = new ReplaySubject<number>();

  @HostBinding('class.responsive-tabs')
  get isScrollable() { return this.listSize > this.hostSize - this.padding; }

  @HostBinding('class.show-left-arrow')
  showLeftArrow = false;

  @HostBinding('class.show-right-arrow')
  showRightArrow = false;

  listeners = [];

  get fraction() { return this.hostSize / SCROLL_OFFSET_FRACTION; }
  get maxLeft() { return this.hostSize - this.listSize - MARGIN / 2; }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.wwidth$.next(event.target.innerWidth);
  }

  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super();

  }

  ngOnInit() {
    this.hscroll$.next(0);
    this.wwidth$.next(window.innerWidth);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.responsiveTabs) {
      if (this.list) {
        this.checkListSize();
      }

    }
  }


  ngOnDestroy(){
    super.ngOnDestroy();
    // Destroy events listeners
    this.listeners.forEach(listener => listener());
  }

  createContainer() {
    const container = this.renderer.createElement('div');
    this.scroller = this.renderer.createElement('div');
    this.renderer.addClass(container, 'tabs-container');
    this.renderer.addClass(this.scroller, 'tabs-scroller');
    this.list = this.el.nativeElement.querySelector('ul.nav-tabs');

    const arrowLeft = this.renderer.createElement('div');
    const arrowRight = this.renderer.createElement('div');
    this.renderer.addClass(arrowLeft, 'tabs-arrow');
    this.renderer.addClass(arrowRight, 'tabs-arrow');
    this.renderer.addClass(arrowLeft, 'tabs-arrow-left');
    this.renderer.addClass(arrowRight, 'tabs-arrow-right');

    this.renderer.addClass(arrowLeft, 'fa');
    this.renderer.addClass(arrowRight, 'fa');

    this.renderer.addClass(arrowLeft, 'fa-caret-left');
    this.renderer.addClass(arrowRight, 'fa-caret-right');

    // // this.renderer.insertBefore(this.el.nativeElement, arrowLeft, this.list);
    // // this.renderer.insertBefore(this.el.nativeElement, arrowRight, this.list);

    // // this.renderer.insertBefore(this.el.nativeElement, container, arrowRight);
    this.renderer.insertBefore(this.el.nativeElement, container, this.list);
    this.renderer.appendChild(container, arrowLeft);
    this.renderer.appendChild(container, this.scroller);
    this.renderer.appendChild(this.scroller, this.list);
    this.renderer.appendChild(container, arrowRight);

    this.listeners.push(this.renderer.listen(this.scroller, 'scroll', (event) => {
      this.leftPos = event.target.scrollLeft;
      this.hscroll$.next(event.target.scrollLeft);
    }));

    this.listeners.push(this.renderer.listen(arrowLeft, 'click', (event) => {
      // console.log('CLICK LEFT');
      this.scroller.scrollBy(-(this.hostSize / SCROLL_OFFSET_FRACTION), 0);
      // this.startScroll('left', event);
    }));
    this.listeners.push(this.renderer.listen(arrowRight, 'click', (event) => {
      // console.log('CLICK RIGHT');
      this.scroller.scrollBy((this.hostSize / SCROLL_OFFSET_FRACTION), 0);
      // this.startScroll('right', event);
    }));

  }

  ngAfterViewInit() {
    // console.log('AFTER VIEW INIT', this.viewContainer);
    this.createContainer();


    Promise.resolve(null).then(() => {
      this.checkListSize();
    });

    combineLatest([
      this.wwidth$.pipe(
        debounceTime(300),
        tap(() => this.checkSize()),
      ),
      this.hscroll$.pipe(
        // tap(scroll => console.log('SCROLL IS ', scroll)),
        debounceTime(100),
      )
    ]).pipe(
      takeUntil(componentDestroyed(this)),
      // tap(([wwidth, scrollLeft]) => console.log('WIDTH', wwidth, scrollLeft))
    ).subscribe(([wwidth, scrollLeft]) => {
      if (this.isScrollable){
        this.showLeftArrow = scrollLeft > 0;
        this.showRightArrow = this.hostSize + scrollLeft + ARROW_MARGIN < this.listSize;

      }

    });

    this.direction.pipe(
      takeUntil(componentDestroyed(this)),
      switchMap((direction) => {
        if (direction) {
          if (direction === 'left') {
            this.scrollLeft();
          } else if (direction === 'right') {
            this.scrollRight();
          }
          return interval(CONTINUOUS_SCROLLING_TIMEOUT_INTERVAL).pipe(
            tap(() => {
              if (direction === 'left') {
                this.scrollLeft();
              } else if (direction === 'right') {
                this.scrollRight();
              }
            })
          );
        } else {
          return EMPTY;
        }

      })
    ).subscribe(direction => {

    });
  }


  protected checkListSize() {

    const items: NodeList = this.list.querySelectorAll('li');
    let size = 0;

    items.forEach((item: any, key) => {
      size += item.clientWidth;
      if (key === 0) {
        this.height = item.clientHeight;
      }
      // console.log('Size is now', size, item.clientWidth, item.clientLeft);
    });
    this.listSize = size + items.length * this.margin;
    // console.log('Check list size', size, items);
    this.renderer.setStyle(this.list, 'min-width', `${this.listSize}px`, RendererStyleFlags2.Important);
    this.renderer.setStyle(this.list, 'height', `${this.height}px`);
    // this.renderer.setStyle(this.el.nativeElement.querySelector('.arrow.left'), 'height', `${this.height}px`);
    // this.renderer.setStyle(this.el.nativeElement.querySelector('.arrow.right'), 'height', `${this.height}px`);
    this.checkSize();
    // this.safetyCheck();
  }

  protected checkSize() {
    Promise.resolve(null).then(() => {
      let el = this.el.nativeElement;
      while (el.clientWidth === 0 && el.parentElement) {
        el = el.parentElement;
      }
      this.hostSize = el.clientWidth;
      if (!this.isScrollable) {
        this.setLeftPos(0);
        this.direction.next(null);
      }

      // console.log('Checked size', this.hostSize, this.el.nativeElement);
    });

  }

  setLeftPos(value: number) {
    this.leftPos = value;
    // this.renderer.setStyle(this.scroller, 'left', `${this.leftPos}px`);
  }

  scrollLeft() {
    // console.log('SCROLL LEFT');
    if (this.isScrollable && this.leftPos < MARGIN) {
      this.setLeftPos(Math.min(0, this.leftPos + this.fraction));
    } else {
      this.direction.next(null);
    }
  }
  scrollRight() {
    // console.log('SCROLL RIGHT');
    if (this.isScrollable && this.leftPos > this.maxLeft) {
      this.setLeftPos(Math.max(this.maxLeft, this.leftPos - (this.hostSize / SCROLL_OFFSET_FRACTION)));
    } else {
      this.direction.next(null);
    }

  }

  startScroll(side: 'left' | 'right', event) {
    this.direction.next(side);
  }

  stopScroll(side: 'left' | 'right', event) {
    this.direction.next(null);
  }

}
