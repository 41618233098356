import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { MIN_WIDTH_BREAKPOINTS } from '../../shared.const';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() count = 0;
  @Input() offset = 0;
  @Input() limit = 50;

  @Input() boundaryLinks = true;
  @Input() directionLinks = true;

  @Input() disabled = false;

  @Input() maxSize = 3;

  @Input() responsive = true;

  @Input() maxSizeSM  = 5;
  @Input() maxSizeMD = 5;
  @Input() maxSizeLG = 9;
  @Input() maxSizeXL = 11;

  protected responsiveSize = this.maxSize;

  @Output() offsetChanged = new EventEmitter<number>();

  page = 0;
  totalPages = 1;


  pages: number[] = [];



  constructor(
    protected breakpointsObserver: BreakpointObserver,
  ) {
    super();
  }

  ngOnInit(): void {
    this.breakpointsObserver.observe([
      MIN_WIDTH_BREAKPOINTS.xl,
      MIN_WIDTH_BREAKPOINTS.lg,
      MIN_WIDTH_BREAKPOINTS.md,
      // MIN_WIDTH_BREAKPOINTS.sm
    ]).pipe(
        takeUntil(componentDestroyed(this))
      ).subscribe(bp => {
        if (!bp.matches){
          this.responsiveSize = this.maxSize;
        } else if (bp.breakpoints[MIN_WIDTH_BREAKPOINTS.xl]){
          this.responsiveSize = this.maxSizeXL;
        } else if (bp.breakpoints[MIN_WIDTH_BREAKPOINTS.lg]){
          this.responsiveSize = this.maxSizeLG;
        } else if (bp.breakpoints[MIN_WIDTH_BREAKPOINTS.md]){
          this.responsiveSize = this.maxSizeMD;
        }
        //   else if (bp.breakpoints[MIN_WIDTH_BREAKPOINTS.sm]){
        //   this.responsiveSize = this.maxSizeSM;
        // }
        if (this.responsive){
          this.setup();
        }
      });
    this.setup();
  }

  ngOnChanges() {
    this.setup();

  }

  setup() {
    this.totalPages = Math.max(Math.ceil(this.count / this.limit), 1);
    this.page = this.offset / this.limit;
    let startPage = 0;
    let endPage = this.totalPages;


    const size = this.responsive ? this.responsiveSize : this.maxSize;
    if (size < this.totalPages) {
      startPage = Math.max(this.page - Math.floor(size / 2), 0);
      endPage = startPage + size;

      // Adjust if limit is exceeded
      if (endPage > this.totalPages) {
        endPage = this.totalPages;
        startPage = endPage - size;
      }
    }

    // console.log('PAGES ARE', {
    //   count: this.count,
    //   limit: this.limit,
    //   offset: this.offset
    // }, startPage, endPage, this.totalPages, this.page, this.pages, size);
    const p = [];

    for (let i = startPage; i < endPage; i++){
      p.push(i);
    }
    this.pages = p;
  }

  noPrevious() { return this.page === 0; }
  noNext() { return this.page === this.totalPages - 1; }
  selectPage(page: number, event?: Event){
    if (event) {
      event.preventDefault();
    }

    this.offsetChanged.emit(page * this.limit);
    // console.log('SELECT PAGE', page);
  }






}
