
<ng-container *transloco="let t">
  <goco-landing-container [title]="cfg && cfg.title && cfg.title[lang] ? cfg.title[lang] : (pageTitle$ | async)">

    <div class="landing-body">

      <p class="text-center" *ngIf="cfg && cfg.subtitle && cfg.subtitle[lang]">{{cfg.subtitle[lang]}}</p>

      <button type="button" (click)="login()"
        class="btn btn-lg btn-brand btn-block">{{t('login.sign_in')}}
      </button>
    </div>

    <div class="landing-postfix" *ngIf="cfg && cfg.postfix && cfg.postfix[lang]">
      <a [href]="cfg.postfix[lang].url">{{cfg.postfix[lang].text}}</a>
    </div>

  </goco-landing-container>
</ng-container>
