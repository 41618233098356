import { Injectable } from '@angular/core';
import { Action, Actions, State, StateContext, Store } from '@ngxs/store';
import * as sysend from 'sysend';
import { TabsMessage, TabsMessageReceived } from './tabs-bus.actions';

const CHANNEL_ID = 'studio-tab';
export class TabsBusStateModel { }


@State<TabsBusStateModel>({
  name: 'tabsBus',
})
@Injectable()
export class TabsBusState {

  constructor(
    protected actions$: Actions,
    protected store: Store,

  ) {
    sysend.on(CHANNEL_ID, (msg) => {
      // console.log('RECEIVED ', msg);
      if (msg) {
        this.store.dispatch(new TabsMessageReceived(msg));
      }

    });
  }
T;

  @Action(TabsMessage)
  sendMessage({ getState, setState }: StateContext<TabsBusStateModel>, { payload }: TabsMessage) {
    const sendPayload = {
      ...payload
    };
    if (sendPayload.dispatch) {
      sendPayload.dispatch = {
        ...sendPayload.dispatch,
        type: sendPayload.dispatch.__proto__.constructor.type,
      };
    }
    // console.log('BROADCAST ', sendPayload);
    sysend.broadcast(CHANNEL_ID, sendPayload);

  }

  @Action(TabsMessageReceived)
  messageReceived({ dispatch }: StateContext<TabsBusStateModel>, { payload }: TabsMessageReceived) {
    if (payload.dispatch) {
      // console.log('DISPATCH', payload.dispatch);
      dispatch(payload.dispatch);
    }
  }
}
