import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { LangService } from './lang.service';


@Injectable({
  providedIn: 'root'
})
export class LangValidatorGuard implements CanActivateChild, CanActivate {


  constructor(
    protected router: Router,
    protected langService: LangService
  ) {
  }


  validateLang(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let newLang = route.params.lang;
    const valid = this.langService.langs.indexOf(newLang) > -1 ? true : false;
    if (!valid) {
      newLang = this.langService.getBestLang();
      this.langService.setLang(newLang);
      return this.router.parseUrl('/' + newLang + state.url.substr(route.params.lang.length + 1));
    }
    if (this.langService.lang !== newLang) {
      this.langService.setLang(newLang);
    }
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateLang(next, state);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (next.firstChild) {
      return true;
    }
    return this.router.createUrlTree(['/']);
  }



}
