
export const DEFAULT_ERRORS = [
  'required',
  'minlength',
  'maxlength',
  'email',
  'enum',
  'tel',
  'url',
  'duplicate'
].reduce((r, c) => {
  r[c] = 'error.form.' + c;

  return r;
}, {});
