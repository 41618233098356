import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { format, utcToZonedTime } from 'date-fns-tz';
import { filter, map, take } from 'rxjs/operators';
import { AppSkinService } from './app-skin.service';
import { IndigoAuthService } from './auth/auth.service';
import { AppConfigService } from './config/app-config.service';
import { TitleService } from './shared/services/title.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  @HostBinding('class.lang-fr')
  isFr = false;

  today = new Date();
  constructor(
    @Inject(DOCUMENT) private document: any,
    private transloco: TranslocoService,
    public skinService: AppSkinService,
    protected title: TitleService,
    protected oidcConfigService: OidcConfigService,
    protected appConfig: AppConfigService,
    protected authService: IndigoAuthService,
    protected router: Router
  ){
    this.appConfig.isReady$.pipe(
      filter(r => r),
      // map(() => oidcConfigService.withConfig(appConfig.getOIDC())),
      take(1)
    ).subscribe(() => {
      oidcConfigService.withConfig(appConfig.getOIDC());
      this.authService.isAuthenticated$.subscribe();
    });
    this.authService.isAuthenticated$.subscribe();
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            gtag('config', environment.production ? 'G-PKT9RBLQNT' : 'G-07K8113XBF',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
  }


  ngOnInit(){
    this.setUpAnalytics();
    this.setLang();
    this.transloco.langChanges$.subscribe(l => {
      this.setLang();
    });


    // // TODO Set title for each page
    // this.skinService.domainConfig$.subscribe((c) => {
    //   this.title.setTitle(`${c.name} Control Panel`);
    // });
  }
  setLang() {
    this.isFr = this.transloco.getActiveLang() === 'fr';
  }

}
