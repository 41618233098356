import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { AppSkinService } from 'src/app/app-skin.service';
import { IDomainConfig } from 'src/app/config/domains-skin.model';

/*



*/


@Injectable({
  providedIn: 'root'
})
export class TitleService {

  domainConf: IDomainConfig = this.skinService.domainConfig;
  sections: string[] = [];
  lang = this.transloco.getActiveLang();
  sections$ = new ReplaySubject<string[]>();

  protected currentTitle = new ReplaySubject<string>();
  get currentTitle$() { return this.currentTitle.asObservable(); }

  constructor(
    protected title: Title,
    public skinService: AppSkinService,
    private transloco: TranslocoService,
  ) {
    this.currentTitle.next(this.title.getTitle());
    this.sections$.next([]);
    combineLatest([
      this.skinService.domainConfig$.pipe(map(c => c.name)),
      transloco.langChanges$.pipe(startWith(this.transloco.getActiveLang)),
      this.sections$.asObservable().pipe(
        // tap(s=> this.sections.change),
        switchMap(sections => sections.length ? combineLatest(
          sections.map(s => this.transloco.selectTranslate(s)
          .pipe(filter(t => t !== s)))) : of([]) )
      )
    ]).subscribe(([domainName, lang, sections]) => {

      const common = lang === 'fr' ? `Panneau de contrôle ${domainName}` : `${domainName} Control Panel`;
      const t = sections.join(' | ')
      + (sections.length ? ' | ' : '')
      + common;
      this.currentTitle.next(t);
      this.title.setTitle(t);
    });

    // EMILE
    // this.skinService.domainConfig$.subscribe((c) => {
    //   this.domainConf = c;
    //   this._setTitle();
    // });
    // this.transloco.langChanges$.subscribe(l => {
    //   this.lang = l;
    //   this._setTitle();
    // });
    // this.transloco.events$
  }


  setSections(names: string[]= []){
    this.sections$.next(names);
    // this.sections = names;
    // this._setTitle();
  }

  protected _setTitle(){
    const t = this.sections.map(s => this.transloco.translate(s)).join(' | ')
      + (this.sections.length ? ' | ' : '')
      + `${this.domainConf.name} Control Panel`;
    this.currentTitle.next(t);
    this.title.setTitle(t);
  }

}
