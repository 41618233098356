import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { WhoAmi } from '../shared/state/auth/auth.actions';
import { AuthState } from '../shared/state/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class IndigoAuthService {

  protected authenticated$ = new ReplaySubject<boolean>();

  get isAuthenticated$() {
    return this.authenticated$.asObservable().pipe(
    );
  }
  get isAnonymous$() {
    return this.authenticated$.asObservable().pipe(
      map(a => !a));
  }

  checkingWhoAmi = false;
  protected whoAmI$;

  get isFullyAuthenticated$() {
    // Used in authenticated.guard since state is no longer stored
    return this.isAuthenticated$.pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          return of(authenticated);
        }
        // If i'm authenticated then
        if (this.store.selectSnapshot(AuthState.getUser)) {
          return of(authenticated);
        } else {
          return this.getWhoAmI().pipe(
            catchError(e => {
              return of(false);
            }),
            map((whoamiAnswer) => {
              return whoamiAnswer;
            })
          );
        }
      })
    );
  }
  constructor(
    protected oidcSecurityService: OidcSecurityService,
    private location: Location,
    protected store: Store
  ) {
    this.oidcSecurityService.checkAuth().subscribe((authenticated) => {
      this.authenticated$.next(authenticated);
    });
  }


  protected getWhoAmI() {
    if (!this.checkingWhoAmi) {
      this.checkingWhoAmi = true;
      this.whoAmI$ = new ReplaySubject<boolean>();
      this.store.dispatch(new WhoAmi()).pipe(
        catchError(e => {
          this.checkingWhoAmi = false;
          this.whoAmI$.next(false);
          return of(null);
        })
      ).subscribe((state) => {
        this.checkingWhoAmi = false;
        this.whoAmI$.next(Boolean(state));
      });
    }
    return this.whoAmI$;
  }
  setPostoginUrl(url: string) {
    const niceUrl = this.location.prepareExternalUrl(url);
    if (!niceUrl.startsWith('/login') && !niceUrl.startsWith('/logout')) {
      localStorage.setItem('postLogin', niceUrl);
    } else {
      localStorage.removeItem('postLogin');
    }
  }

  getPostLoginUrl() {
    let newRoute = localStorage.getItem('postLogin');
    if (newRoute) {
      localStorage.removeItem('postLogin');
      if (newRoute.startsWith('/login') || newRoute.startsWith('/logout')) {
        newRoute = undefined;
      }
    }
    if (!newRoute) {
      newRoute = '/__/app/account';
    }
    return newRoute;
  }

  authorize(postLoginUrl: string, lang: string) {
    if (postLoginUrl) {
      this.setPostoginUrl(postLoginUrl);
    }
    // console.log("Languge is: ", lang);
    return this.oidcSecurityService.authorize({
      // urlHandler,
      customParams: {
        acr_values: 'Multi_Factor Single_Factor',
        ui_locales: lang,
      }
    });
  }

  getUserData$() {
    return this.oidcSecurityService.userData$;
  }
}
