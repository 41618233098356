<ul class="pagination">
  <li class="pagination-first page-item" *ngIf="boundaryLinks"
    [class.disabled]="noPrevious() || disabled">
    <a class="page-link" href (click)="selectPage(0, $event)">
      &lt;&lt;
    </a>
  </li>

  <li class="pagination-prev page-item" *ngIf="directionLinks"
    [class.disabled]="noPrevious() || disabled">
    <a class="page-link" href (click)="selectPage(page - 1, $event)">
      &lt;
    </a>
  </li>

  <li *ngFor="let pg of pages" [class.active]="pg === page"
    [class.disabled]="disabled && pg !==page" class="pagination-page page-item">
    <a class="page-link" href (click)="selectPage(pg, $event)">
      {{pg + 1}}
    </a>
  </li>

  <li class="pagination-next page-item" *ngIf="directionLinks"
    [class.disabled]="noNext() || disabled">
    <a class="page-link" href (click)="selectPage(page + 1, $event)">
      &gt;
    </a>
  </li>

  <li class="pagination-last page-item" *ngIf="boundaryLinks"
    [class.disabled]="noNext() || disabled">
    <a class="page-link" href (click)="selectPage(totalPages -1, $event)">
      &gt;&gt;
    </a>
  </li>
</ul>