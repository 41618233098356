import { HttpErrorResponse } from '@angular/common/http';
import { ISilentError } from 'src/app/errors/studio-errors.model';

export interface IStudioErrorData {
  prop?: string | string[];
  val?: any;
  msg?: string;
}

export interface IStudio3ApiError {
  status: number;
  data?: IStudioErrorData;
  error: string;
}

const FIX_ERROR = {
  enum: 'validation.enum'
};


// Treat Studio3 Api Error from HttpErrorReponse
export class Studio3ApiError implements IStudio3ApiError, ISilentError {
  status: number;
  data: IStudioErrorData;
  error = 'api.unknown';

  constructor(public originalError?: Error | any, public isSilent = false) {
    console.warn('Original Error is ', originalError);
    if (originalError && originalError instanceof HttpErrorResponse) {
      this.status = originalError.status;
      if (originalError.error){
        if (typeof originalError.error === 'object'){
          if (originalError.error.error){
            // console.log(' --- Error shoud be ', originalError.error.error, this.error);
            this.error = originalError.error.error || 'api.unknown';
          }
          this.data = originalError.error.error_data;
          // console.log(' --- Error is now ', originalError.error.error, this.error);
        } else {
          console.error('Unknown error type', originalError);
        }
      }
    }
    switch (this.status){
      case 0:
        this.error = 'api.unreachable';
        break;
      case 500:
      case 401:
      case 403:
        this.error = `api.${String(this.status)}`;
    }

    if (!this.error){
      this.error = 'api.unknown';
    }

    if (FIX_ERROR[this.error]){
      this.error = FIX_ERROR[this.error];
    }

  }

  static getApiError(originalError: Error, isSilent = false){
    if (originalError instanceof Studio3ApiError){
      return originalError;
    }
    return new Studio3ApiError(originalError, isSilent);
  }


}

export interface IStudioUnknownErrorData extends IStudioErrorData{
  request?: { url?: string, method?: string, query?: any, body?: any};
  response: any;
}
// Treat unexpected data returned by api without Http Error Code
export class Studio3ApiUnknownError extends Studio3ApiError {
  data: IStudioUnknownErrorData;
  constructor(response: any, request?: { url?: string, method?: string, query?: any, body?: any}){
    super(new Error('Studio3UnknownError'));

    this.data = {request, response};
  }
}
