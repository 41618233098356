import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeUri'
})
export class EncodeUriPipe implements PipeTransform {

  transform(value: string, component: boolean = true): string {
    if (component){
      return encodeURIComponent(value);
    } else {
      return encodeURI(value);
    }

  }

}
