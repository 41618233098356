import { InjectionToken } from '@angular/core';

export interface IFormGroupComponent {
  isDirtyAndTouched: boolean;
  hasErrors: boolean;
  messages: () => {msg: string, vars: object }[];
}
export abstract class FormGroupComponentParent implements IFormGroupComponent {
  isDirtyAndTouched: boolean;
  hasErrors: boolean;
  messages: () => { msg: string; vars: object; }[];

}
