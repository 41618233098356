import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, NumberFormat, parsePhoneNumberFromString } from 'libphonenumber-js';
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, options?: {
    country?: CountryCode,
    format?: NumberFormat
  }): string {

    try {
      const parsed = parsePhoneNumberFromString(value, options?.country || 'CA');
      if (parsed){
        const format = options?.format  || (parsed.countryCallingCode === '1' ? 'NATIONAL' : 'INTERNATIONAL');
        const formated = parsed.format(format);
        return formated || value;
      }
    }catch (e) {
      console.warn('Parsing error', e);
    }

    return value;
  }

}
