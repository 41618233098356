import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LangService } from './lang.service';

@Injectable({providedIn: 'root'})
export class LangResolver implements Resolve<string> {
  constructor(private langService: LangService){

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | Observable<string> | Promise<string> {
    const lang = this.langService.getBestLang();
    this.langService.setLang(lang);
    return lang;
  }

}
