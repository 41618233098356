import { IIndigoConfig } from './app-config.model';

export const DEFAULT_INDIGO_CONFIG: Partial<IIndigoConfig> = {
  theme: 'ubity',
  oidc: {
    url: 'gocob2c.onmicrosoft.com/9a28ed30-7a37-4753-a113-b65651b58633/v2.0/',
    client_id: 'b4ede085-e0cc-42f7-abf4-1ac28afd8681', // indigo_ng_app
    signupSigninPolicy: 'B2C_1_sign_in',
    tenantName: 'gocob2c',
    response_type: 'code', // Soufiane avait code id_token ...authorization code AND id token
    auto_userinfo: false,
    header_prefix: "Bearer-az2",
    scope: 'https://gocob2c.onmicrosoft.com/b4ede085-e0cc-42f7-abf4-1ac28afd8681/access_as_user openid profile', // indigo_ng_app
    well_known_endpoint: 'https://gocob2c.b2clogin.com/gocob2c.onmicrosoft.com/B2C_1_sign_in/v2.0/.well-known/openid-configuration',
  }
};

// production

// export const DEFAULT_INDIGO_CONFIG: Partial<IIndigoConfig> = {
//   theme: 'ubity',
//   oidc: {
//     url: 'gocob2c.onmicrosoft.com/9a28ed30-7a37-4753-a113-b65651b58633/v2.0/',
//     client_id: 'b4ede085-e0cc-42f7-abf4-1ac28afd8681', // indigo_ng_app
//     signupSigninPolicy: 'B2C_1_sign_in',
//     tenantName: 'gocob2c',
//     response_type: 'code', // Soufiane avait code id_token ...authorization code AND id token
//     auto_userinfo: false,
//     header_prefix: "Bearer-az2",
//     scope: 'https://gocob2c.onmicrosoft.com/b4ede085-e0cc-42f7-abf4-1ac28afd8681/access_as_user openid profile', // indigo_ng_app
//     well_known_endpoint: 'https://gocob2c.b2clogin.com/gocob2c.onmicrosoft.com/B2C_1_sign_in/v2.0/.well-known/openid-configuration',
//   }
// };

// Production

// - oidc.url (tenant ID) 

// https://gocob2c.onmicrosoft.com/9a28ed30-7a37-4753-a113-b65651b58633 

// - oidc.client_id 

// f340a38c-1191-4395-ba0f-61105b1e6175 

// - oidc.signupSigninPolicy	 

// B2C_1_sign_in 

// - oidc.tenantName 

// gocob2c 

// - oidc.scope 

// https://gocob2c.onmicrosoft.com/f340a38c-1191-4395-ba0f-61105b1e6175/access_as_user 

// - oidc.well_known_endpoint 

// https://gocob2c.b2clogin.com/gocob2c.onmicrosoft.com/B2C_1_sign_in/v2.0/.well-known/openid-configuration 





// staging

// export const DEFAULT_INDIGO_CONFIG: Partial<IIndigoConfig> = {
//   theme: 'ubity',
//   oidc: {
//     url: 'https://gocob2cdev.b2clogin.com/8f70c1ad-9357-4fe5-9a60-2df469edebc2/v2.0/',
//     client_id: '9c726916-7efd-4e6c-9d9f-727c29859192', // indigo_ng_app
//     signupSigninPolicy: 'B2C_1_sign_in',
//     tenantName: 'GoCoB2CDEV',
//     response_type: 'code', // Soufiane avait code id_token ...authorization code AND id token
//     auto_userinfo: false,
//     header_prefix: "Bearer-az2",
//     scope: 'https://GoCoB2CDEV.onmicrosoft.com/9c726916-7efd-4e6c-9d9f-727c29859192/access_as_user openid profile', // indigo_ng_app
//     well_known_endpoint: 'https://GoCoB2CDEV.b2clogin.com/GoCoB2CDEV.onmicrosoft.com/b2c_1_sign_in/v2.0/.well-known/openid-configuration',
//   }
// };

