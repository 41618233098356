import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject } from 'rxjs';
import { LangService } from 'src/app/lang/lang.service';

export interface SmartSelectorService<T> {
  results$: Observable<T[]>;
  typeahead: Subject<string>;
  loading$: Observable<boolean>;
  idProperty?: string;
  labelProperty?: string;
  labelPropi18n?: boolean;
  // labelTmp?: string;
  // optionTmp?: string;
  addressProp?: string;
}
@Component({
  selector: 'app-smart-select',
  templateUrl: './smart-select.component.html',
  styleUrls: ['./smart-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartSelectComponent),
      multi: true
    }
  ]
})
export class SmartSelectComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {



  @Input() placeholder = '';
  @Input() clearable = true;
  @Input() markFirst = false;
  @Input() multiple = false;
  @Input() selectOnTab = true;
  @Input() editableSearchTerm = false;
  @Input() source: SmartSelectorService<any>;
  @Input() isOpen: boolean;
  @Input() loadingText: string;
  @Input() clearAllText: string;
  disabled = false;

  value: string | number;

  results$: Observable<any[]>;
  labelProp: string;
  idProp: string;
  typeahead: Subject<string>;
  loading$: Observable<boolean>;

  labelPropi18n: boolean;
  i18nSuffix$: Observable<string> = this.langService.getLangSuffix$();


  addressProp: string;

  @Output() selectClear = new EventEmitter();
  @Output() selectSearch = new EventEmitter<string>();
  @Output() selectChange = new EventEmitter<any>();


  @ViewChild(NgSelectComponent, {static: true}) select: NgSelectComponent;
  constructor(
    public langService: LangService
  ) {

  }

  onChange = (val: any) => {};
  onTouched = () => {};


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source){
      this.setupSource();
    }
  }

  setupSource(){
    if (this.source){
      this.results$ = this.source.results$;
      this.labelProp = this.source.labelProperty;
      this.idProp = this.source.idProperty;
      this.typeahead = this.source.typeahead;
      this.loading$ = this.source.loading$;
      this.labelPropi18n = this.source.labelPropi18n;
      this.addressProp = this.source.addressProp;

    } else {
      this.results$ = of(null);
      this.labelProp = null;
      this.idProp = null;
      this.typeahead = null;
      this.loading$ = of(null);
      this.labelPropi18n = null;
      this.addressProp = null;
    }


  }

  ngOnDestroy(): void {

  }

  writeValue(obj: string | number): void {
    this.value = obj;
  }
  registerOnChange(fn) { this.onChange = fn;  }
  registerOnTouched(fn) { this.onTouched = fn; }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setValue(value: string) {
    this.value = value;
    this.onChange(this.value);
  }

}
