
export interface TabsMessagePayload {
  state: string;
  action: 'patch' | 'set';
  data: any;
  dispatch?: any;
}
export class TabsMessage {
  static readonly type = '[TabsBus] Msg';
  constructor(public payload: TabsMessagePayload) { }
}

export class TabsMessageReceived {
  static readonly type = '[TabsBus] Received';
  constructor(public payload: TabsMessagePayload) { }
}
