import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { merge, Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil, delay } from 'rxjs/operators';
import { LangService } from 'src/app/lang/lang.service';
import { OnDestroyMixin, componentDestroyed } from '@w11k/ngx-componentdestroyed';


export interface ModalRouteConfig extends ModalOptions {
  component: string;
  i18nscope?: string;
  outlet?: string;
}
@Component({
  selector: 'app-modal-route',
  template: '<ngx-loading [show]="loading"></ngx-loading>',
  styles: []
})
export class ModalRouteComponent extends OnDestroyMixin implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  onHide: Subscription;
  exit = new Subject();
  loading = true;


  constructor(
    protected modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private langService: LangService
  ) {
    super();
  }

  ngOnInit(): void {
    // console.log('Open modal First time', this.route.snapshot.toString(), this.router.url, this.route.snapshot);

    this.openModal();

    this.router.events.pipe(
      takeUntil(merge(componentDestroyed(this), this.exit)),
      filter(e => e instanceof NavigationEnd)
    ).subscribe((e) => {


      if (this.onHide && !this.onHide.closed) {
        this.onHide.unsubscribe();
        // console.log('Closed sub');
      }
      if (this.modalRef) {
        this.modalRef.hide();
        // console.log('Modal hidden');
      }

      let lastChild = this.router.routerState.snapshot.root;
      while (lastChild.firstChild) {
        lastChild = lastChild.firstChild;
      }
      // console.log('Router event', e, lastChild);


      if (lastChild.component === ModalRouteComponent) {
        // console.log('Open modal', this.route.snapshot.toString(), this.router.url);
        this.openModal();
      } else {
        delete this.modalRef;
      }
    });

  }


  openModal() {

    const options: ModalRouteConfig = this.route.snapshot.data.modal;
    if (options.i18nscope) {
      // Wait for transloco scope to be loaded
      this.loading = true;
      this.langService.scopes$.pipe(
        filter(s => s[this.langService.lang] && s[this.langService.lang].indexOf(options.i18nscope) > -1),
        take(1),
        delay(0),
      ).subscribe(() => {

        this.doOpen(options);
        this.loading = false;
      });

    } else {
      this.doOpen(options);
      this.loading = false;
    }
  }

  doOpen(options: ModalRouteConfig) {
    const modalConfig: any = { ...options};
    delete modalConfig.component;
    delete modalConfig.i18nscope;
    delete modalConfig.outlet;
    if (!modalConfig.initialState){
      modalConfig.initialState = {};
    }
    modalConfig.initialState.route = this.route;
    this.modalRef = this.modalService.show(options.component, modalConfig);
    this.onHide = this.modalService.onHide.subscribe((data) => {



      this.exit.next(true);
      if (options.outlet){
        this.router.navigate([{outlets: { [options.outlet]: null}}]);
      } else {
        this.router.navigate(['..'], { relativeTo: this.route });
      }

    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // console.warn('OnDestroy');
    if (this.onHide && !this.onHide.closed) {
      // console.warn('OnDestroy Unsubscribe');
      this.onHide.unsubscribe();
    }
    if (this.modalRef) {
      // console.warn('OnDestroy hide');
      this.modalRef.hide();
    }

  }



}
