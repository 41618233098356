import { IDomainConfig } from './domains-skin.model';
import { ShowNotifs } from '../private/states/notifs/notif.actions';

const UbityDomain: IDomainConfig = {
  name: 'GoCo', favicon: 'assets/themes/ubity/favicon.ico',
  footer: [


    // {
    //   title: { fr: 'Assistance téléphonique', en: 'Helpline' },
    //   items: [
    //     {
    //       type: 'phone', number: '877-439-4626',
    //       label: { fr: 'NUMÉRO SANS FRAIS', en: 'CALL US - TOLL FREE' }
    //     }
    //   ]
    // },

    // Was removed lot's of code starting here


    // {
    //   title: { fr: 'Assistance', en: 'Support' },
    //   items: [
    //     {
    //       type: 'menu',
    //       items: [
    //         {
    //           url: { fr: 'https://support.ubity.com/tech/', en: 'https://support.ubity.com/tech/en/' },
    //           label: { fr: 'Centre d\'aide', en: 'Help center' }
    //         },
    //         {
    //           action: new ShowNotifs('updates'),
    //           label: { fr: 'Mise à jour récentes', en: 'Recent updates' }
    //         },
    //       ]
    //     }
    //   ]
    // },


    // 1
    {
      title: { fr: 'À propos de nous', en: 'About us' },
      items: [
        {
          type: 'menu',
          items: [
            {
              url: {
                fr: 'https://www.goco.ca/fr/', en: 'https://goco.ca',
                fr_staging: 'https://www.goco.ca/fr/', en_staging: 'https://goco.ca',
              },
              label: { fr: 'Goco.ca', en: 'Goco.ca' }
            },
            // {
            //   // url: { fr: 'https://www.ubity.com/fr/carri%C3%A8res', en: 'https://www.ubity.com/careers' },
            //   label: { fr: 'Mise-à-jour récente', en: 'Recent updates' }
            // },
            {
              url: {
                fr: 'https://www.goco.ca/fr/carrieres/', en: 'https://www.goco.ca/careers/',
                fr_staging: 'https://www.goco.ca/fr/carrieres/', en_staging: 'https://www.goco.ca/careers/',
               },
              label: { fr: 'Carrières', en: 'Careers' }
            }
          ]
        }
      ]
    },

// 2
{
  title: { fr: 'Assistance', en: 'Support' },
  items: [
    {
      type: 'menu',
      items: [
        {
          url: {
            fr: 'https://goco.service-now.com/csm',
            en: 'https://goco.service-now.com/csm',
            fr_staging: 'https://goco.service-now.com/csm',
            en_staging: 'https://goco.service-now.com/csm',
          },
          label: { fr: "Portail du service client", en: 'Customer care portal' }
        },
        {
          url: {
            fr: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
            en: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
            fr_staging: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
            en_staging: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
            },
          label: { fr: 'Mes cas et requêtes', en: 'My cases & requests' }
        }
      ]
    }
  ]
},

// 3
{
  title: { fr: 'Assistance téléphonique', en: 'Helpline' },
  items: [
    {
      type: 'menu',
      items: [
        {
          // url: { fr: '', en: '' },
          label: { fr: '+1 877 439 4626', en: '+1 877 439 4626' }
        }
      ]
    }
  ]
},

// 4
{
  title: { fr: 'Contactez-nous', en: 'Contact' },
  items: [
    {
      type: 'menu',
      items: [
        {
          url: {
            fr: 'mailto:soutien-donnees@goco.ca', en: 'mailto:data-support@goco.ca',
            fr_staging: 'mailto:soutien-donnees@goco.ca', en_staging: 'mailto:data-support@goco.ca',
           },
          label: { fr: 'soutien-donnees@goco.ca', en: 'data-support@goco.ca' }
        },
        {
          url: {
            fr: 'mailto:soutien-voix@goco.ca', en: 'mailto:voice-support@goco.ca',
            fr_staging: 'mailto:soutien-voix@goco.ca', en_staging: 'mailto:voice-support@goco.ca',
           },
          label: { fr: 'soutien-voix@goco.ca', en: 'voice-support@goco.ca' }
        }
      ]
    }
  ]
},




    // {
    //   title: { fr: 'Parrainez une entreprise', en: 'Refer a business' },
    //   items: [
    //     {
    //       type: 'refer', url: '/refer-a-business', img: 'assets/themes/indigo/speaker_sm.svg',
    //       label: { fr: 'Référez une entreprise à GoCo<br />et recevez 500 $', en: 'Refer a business to GoCo<br />and receive 500 $' }
    //     }
    //   ]
    // }






    // to there


  ],
  site_uri: {
    fr: 'https://my.goco.ca/fr/app/account/__/dashboard', en: '// https://my.goco.ca/en/app/account/__/dashboard'
  },
  help_uri: {
    fr: 'https://goco.service-now.com/csm', en: 'https://goco.service-now.com/csm',
    fr_staging: 'https://goco.service-now.com/csm', en_staging: 'https://goco.service-now.com/csm'
  },
  // voice_uri: { // Introduced in Dec 2021 by Fro
  //   fr: 'https://goco.myossbss.com/customers/sp-summary/?ljs=fr', en: 'https://goco.myossbss.com/',
  //   fr_staging: 'https://beta-goco.myossbss.com/social/login/azureb2c/', en_staging: 'https://beta-goco.myossbss.com/social/login/azureb2c/'
  // },
  // voice_uri: { // Introduced in Dec 2021 by Fro
  //   fr: 'https://goco.myossbss.com/social/login/azureb2c/', en: 'https://goco.myossbss.com/social/login/azureb2c/',
  //   fr_staging: 'https://beta-goco.myossbss.com/social/login/azureb2c/', en_staging: 'https://beta-goco.myossbss.com/social/login/azureb2c/'
  // },

  voice_uri: { // Introduced in May 10th 2022 by Fro
    fr: 'https://voice.goco.ca/social/login/azureb2c/', en: 'https://voice.goco.ca/social/login/azureb2c/',
    fr_staging: 'https://voice.goco.ca/social/login/azureb2c/', en_staging: 'https://voice.goco.ca/social/login/azureb2c/'
  },

  maintenance_uri: {
    fr: 'https://goco.service-now.com/csm?id=services_status', en: 'https://goco.service-now.com/csm?id=services_status'
  },
 case_requests_uri: {
    fr: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
    en: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
    fr_staging: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
    en_staging: 'https://goco.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
  },
  status_page: 'z92x1bh5z7c4',
  // maintenance_endpoint: 'https://z92x1bh5z7c4.statuspage.io/api/v2/scheduled-maintenances/upcoming.json',
  // status_uri: 'https://z92x1bh5z7c4.statuspage.io/api/v2/status.json',
  refer: {
    uri: '/refer-a-business',
    label: {
      en: 'Refer a business to GoCo and receive 500$',
      fr: 'Référez une entreprise à GoCo et recevez 500$'
    }
  },
  copyright: {
    fr: 'GoCo Technologie, S.E.C',
    en: 'GoCo Technology LP'
  }
};

const UbityIndigo: IDomainConfig = {
  ...UbityDomain,
  favicon: 'assets/themes/indigo/favicon_io/favicon.ico',
};

export const DomainConfigs: { [theme: string]: IDomainConfig } = {
  // cooptel: {
  //   name: 'Cooptel', favicon: 'assets/themes/cooptel/favicon.ico',
  //   footer: [
  //     {
  //       title: { fr: 'Services', en: 'Services' },
  //       items: [
  //         {
  //           type: 'menu', items: [
  //             {
  //               url: { fr: 'https://www.cooptel.ca/', en: 'https://www.cooptel.ca/' },
  //               label: { fr: 'Résidentiel', en: 'Residential services' }
  //             },
  //             {
  //               url: { fr: 'https://www.cooptel.ca/', en: 'https://www.cooptel.ca/commercial/' },
  //               label: { fr: 'Affaires', en: 'Business services' }
  //             },
  //             {
  //               url: { fr: 'https://www.cooptel.ca/', en: 'https://secure.coopmail.coop/' },
  //               label: { fr: 'CoopMail', en: 'CoopMail' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'À propos', en: 'About' },
  //       items: [
  //         {
  //           type: 'menu', items: [
  //             {
  //               url: { fr: 'https://www.cooptel.ca/contact/', en: 'https://www.cooptel.ca/contact/' },
  //               label: { fr: 'Contactez-nous', en: 'Contact us' }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },



  ubity: UbityIndigo,
  // namaio: UbityIndigo,
  // quebec: UbityIndigo,
  // xittel: {
  //   name: 'Maskatel', favicon: 'assets/themes/xittel/favicon.ico',
  //   footer: [
  //     {
  //       title: { fr: 'Support technique', en: 'Helpline' },
  //       items: [
  //         {
  //           type: 'phone', number: '877-627-5283',
  //           label: { fr: 'NUMÉRO SANS FRAIS', en: 'CALL US - TOLL FREE' }
  //         },
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'https://maskatel.ca/services-residentiels/nous-joindre/', en: 'https://maskatel.ca/services-residentiels/nous-joindre/' },
  //               label: { fr: 'Voir nos coordonnées complètes', en: 'See our full details' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Maskatel', en: 'Maskatel' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'https://maskatel.ca/services-residentiels/internet/', en: 'https://maskatel.ca/en/residential-services/internet/' },
  //               label: { fr: 'Résidentiel', en: 'Residential' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/', en: 'https://maskatel.ca/en/business-solutions/' },
  //               label: { fr: 'Affaires', en: 'Business' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/termes-et-conditions/', en: 'https://maskatel.ca/en/business-solutions/terms-and-conditions/' },
  //               label: { fr: 'Termes et conditions', en: 'Terms and conditions' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Services évolués d\'affaires', en: 'Enhanced business solutions' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/services-evolues/', en: 'https://maskatel.ca/en/business-solutions/enhanced-services/' },
  //               label: { fr: 'Service de colocation', en: 'Colocation service' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/services-evolues/', en: 'https://maskatel.ca/en/business-solutions/enhanced-services/' },
  //               label: { fr: 'Extension de réseau local', en: 'Extension of the local network' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/services-evolues/', en: 'https://maskatel.ca/en/business-solutions/enhanced-services/' },
  //               label: { fr: 'Solution infonuagique', en: 'Cloud-based solutions' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: '', en: '' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/services-evolues/', en: 'https://maskatel.ca/en/business-solutions/enhanced-services/' },
  //               label: { fr: 'Lien de relêve sans-fil', en: 'Wireless link' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/services-evolues/', en: 'https://maskatel.ca/en/business-solutions/enhanced-services/' },
  //               label: { fr: 'Internet dédié sur fibre', en: 'Dedicated Fibre Optic' }
  //             },
  //             {
  //               url: { fr: 'https://maskatel.ca/services-affaires/nous-joindre/#contact', en: 'https://maskatel.ca/en/business-solutions/contact-us/#contact' },
  //               label: { fr: 'Solutions sur mesure', en: 'Tailored solutions and personalized support' }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },




  // telox: {
  //   name: 'TelOX',
  //   footer: [
  //     {
  //       title: { fr: 'Soutien TelOX', en: 'TelOX support' },
  //       items: [
  //         {
  //           type: 'phone', number: '514-666-4357',
  //           label: { fr: 'NUMÉRO SANS FRAIS', en: 'CALL US - TOLL FREE' }
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Nous contacter', en: 'Contact us' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'mailto:support@helpox.com', en: 'mailto:support@helpox.com' },
  //               label: { fr: 'support@helpox.com', en: 'support@helpox.com' }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },




  // telenet: {
  //   name: 'Telenet', favicon: 'assets/themes/telenet/favicon.ico',
  //   footer: [
  //     {
  //       title: { fr: 'Liens rapides', en: 'Quick links' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'http://www.telenetinfo.com/telenet/services/support-technique', en: 'http://www.telenetinfo.com/telenet/services/support-technique' },
  //               label: { fr: 'Support technique', en: 'Technical Support' }
  //             },
  //             {
  //               url: { fr: 'https://exchange.telenetinfo.com/owa/auth/', en: 'https://exchange.telenetinfo.com/owa/auth/' },
  //               label: { fr: 'Zone employés', en: 'Employee Zone' }
  //             },
  //             {
  //               url: { fr: 'https://exchange.telenetinfo.net/owa/auth/logon.aspx?replaceCurrent=1&url=https%%3a%%2f%%2fexchange.telenetinfo.net%%2fowa', en: 'https://exchange.telenetinfo.net/owa/auth/logon.aspx?replaceCurrent=1&url=https%%3a%%2f%%2fexchange.telenetinfo.net%%2fowa' },
  //               label: { fr: 'Exchange clients', en: 'Exchange clients' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'À propos', en: 'About' },
  //       items: [
  //         {
  //           type: 'menu',
  //           items: [
  //             {
  //               url: { fr: 'http://www.telenetinfo.com/telenet/info/nos-certifications', en: 'http://www.telenetinfo.com/telenet/info/nos-certifications' },
  //               label: { fr: 'Nos certifications', en: 'Our certifications' }
  //             },
  //             {
  //               url: { fr: 'http://www.telenetinfo.com/telenet/info/a-propos', en: 'http://www.telenetinfo.com/telenet/info/a-propos' },
  //               label: { fr: 'À propos', en: 'About' }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Partenaires technologiques', en: 'Technology partners' },
  //       items: [
  //         {
  //           type: 'partners',
  //           imgs: [
  //             { src: 'assets/themes/telenet/logo_footer-01.svg', style: 'height: 25px;' },
  //             { src: 'assets/themes/telenet/logo_footer-02.svg', style: 'height: 25px;' },
  //             { src: 'assets/themes/telenet/logo_footer-03.svg', style: 'height: 15px;' },
  //             { src: 'assets/themes/telenet/logo_footer-04.svg', style: 'height:12px; margin-top: 2px;' },
  //             { src: 'assets/themes/telenet/logo_footer-05.svg', style: 'height: 20px; margin-top: 2px;' },
  //           ],
  //           all_label: { fr: 'Voir tous les partenaires', en: 'See all partners' },
  //           all_url: { fr: 'http://www.telenetinfo.com/telenet/info/partenaires', en: 'http://www.telenetinfo.com/telenet/info/partenaires' },
  //         }
  //       ]
  //     }
  //   ]
  // },




  // vocem: {
  //   name: 'Decibel', favicon: 'assets/themes/vocem/favicon.ico',
  //   footer: [
  //     {
  //       title: { fr: 'Support technique', en: 'Helpline' },
  //       items: [
  //         {
  //           type: 'phone', number: '844-922-1822',
  //           label: { fr: 'NUMÉRO SANS FRAIS', en: 'CALL US - TOLL FREE' }
  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Nous contacter', en: 'Contact us' },
  //       items: [
  //         {
  //           type: 'menu', items: [
  //             {
  //               label: { fr: 'ventes@vocem.biz', en: 'ventes@vocem.biz' },
  //               url: { fr: 'mailto:ventes@vocem.biz', en: 'mailto:ventes@vocem.biz' }
  //             }
  //           ]

  //         }
  //       ]
  //     }
  //   ]
  // },



  // ordinacoeur: {
  //   name: 'Ordinacoeur', favicon: 'assets/themes/ordinacoeur/favicon.ico',
  //   footer: [
  //     {
  //       title: { fr: 'Support technique', en: 'Helpline' },
  //       items: [
  //         {
  //           type: 'phone', number: '514-861-2344',
  //           label: { fr: 'NUMÉRO SANS FRAIS', en: 'CALL US - TOLL FREE' }
  //         },
  //         {
  //           type: 'menu', items: [
  //             {
  //               label: { fr: 'Voir nos coordonnées complètes', en: 'See our full details' },
  //               url: { fr: 'http://ordinacoeurrt.ca/nous-joindre/', en: 'http://ordinacoeurrt.ca/nous-joindre/' }
  //             }
  //           ]

  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Heures d\'ouverture', en: 'Business hours' },
  //       items: [
  //         {
  //           type: 'menu', items: [
  //             { label: { fr: 'Lundi au jeudi : 8h à 17 h', en: 'Monday to Thursday: 8 am to 5 pm' } },
  //             { label: { fr: 'Vendredi : 8 h à 15 h', en: 'Friday: 8 am to 3 pm' } }
  //           ]

  //         }
  //       ]
  //     },
  //     {
  //       title: { fr: 'Nous contacter', en: 'Contact us' },
  //       items: [
  //         {
  //           type: 'menu', items: [
  //             {
  //               label: { fr: 'info@ort360.ca', en: 'info@ort360.ca' },
  //               url: { fr: 'mailto:info@ort360.ca', en: 'mailto:info@ort360.ca' }
  //             }
  //           ]

  //         }
  //       ]
  //     }
  //   ]
  // }


};
