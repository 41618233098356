import { Directive, HostBinding, Input, isDevMode, HostListener, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { QueryParamsHandling, Router, ActivatedRoute, NavigationEnd, UrlTree, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationStrategy } from '@angular/common';

@Directive({
  selector: 'a[smartLink], area[smartLink]'
})
export class SmartLinkWithHrefDirective implements OnDestroy, OnChanges {

  // TODO(issue/24571): remove '!'.
  @HostBinding('attr.target') @Input() target!: string;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#queryParams NavigationExtras#queryParams}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() queryParams!: { [k: string]: any };
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#fragment NavigationExtras#fragment}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() fragment!: string;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#queryParamsHandling NavigationExtras#queryParamsHandling}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() queryParamsHandling!: QueryParamsHandling;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#preserveFragment NavigationExtras#preserveFragment}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() preserveFragment!: boolean;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#skipLocationChange NavigationExtras#skipLocationChange}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() skipLocationChange!: boolean;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#replaceUrl NavigationExtras#replaceUrl}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  // TODO(issue/24571): remove '!'.
  @Input() replaceUrl!: boolean;
  /**
   * Passed to {@link Router#createUrlTree Router#createUrlTree} as part of the `NavigationExtras`.
   * @see {@link NavigationExtras#state NavigationExtras#state}
   * @see {@link Router#createUrlTree Router#createUrlTree}
   */
  @Input() state?: { [k: string]: any };
  @Input() protected smartLink: { url?: string, route?: any[] };
  protected subscription: Subscription;
  // TODO(issue/24571): remove '!'.
  protected preserve!: boolean;

  // the url displayed on the anchor element.
  // TODO(issue/24571): remove '!'.
  @HostBinding() href!: string;

  constructor(
    private router: Router, private route: ActivatedRoute,
    private locationStrategy: LocationStrategy) {
    this.subscription = router.events.subscribe((s: Event) => {
      if (s instanceof NavigationEnd) {
        this.updateTargetUrlAndHref();
      }
    });
  }



  /**
   * @deprecated As of Angular v4.0 use `queryParamsHandling` instead.
   */
  @Input()
  set preserveQueryParams(value: boolean) {
    if (isDevMode() && (console as any) && (console.warn as any)) {
      console.warn('preserveQueryParams is deprecated, use queryParamsHandling instead.');
    }
    this.preserve = value;
  }

  ngOnChanges(changes: {}): any {
    this.updateTargetUrlAndHref();
  }
  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }
    if (!this.smartLink.route && this.smartLink.url) {
      return true;
    }

    if (this.smartLink.route) {
      const extras = {
        skipLocationChange: attrBoolValue(this.skipLocationChange),
        replaceUrl: attrBoolValue(this.replaceUrl),
        state: this.state
      };
      this.router.navigateByUrl(this.urlTree, extras);
    }
    return false;
  }

  private updateTargetUrlAndHref(): void {
    if (this.smartLink.route) {
      this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
    } else if (this.smartLink.url) {
      this.href = this.smartLink.url;
    }
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.smartLink.route, {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      preserveQueryParams: attrBoolValue(this.preserve),
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }

}


function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}
