import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { format } from 'date-fns';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import {PRESET_PERIODS, SelectPeriod, SetCustomPeriod} from '../../state/period/period.actions';
import { PeriodState } from '../../state/period/period.state';


@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent extends OnDestroyMixin implements OnInit {

  readonly periods = PRESET_PERIODS;

  selected: string;
  customRange: any = this.store.selectSnapshot(PeriodState.getCustomRange);
  selectedPeriod$ = this.store.select(PeriodState.getSelectedPeriod);

  // @HostBinding('class.edit-custom')
  showRangeSelector = false;

  @ViewChild('dropdown', {static: false}) dropdown: BsDropdownDirective;
  constructor(
    protected store: Store
  ) {
    super();
    this.store.select(PeriodState.getSelected).pipe(
      untilComponentDestroyed(this)
    ).subscribe(l => {
      this.selected = l;
      // if (l !== 'custom'){
      //   const currentPeriod = this.store.selectSnapshot(PeriodState.getSelectedPeriod);
      //   this.customRange = `${format(currentPeriod.start, 'yyyy-MM-dd')} - ${format(currentPeriod.end, 'yyyy-MM-dd')}`;
      // }

    });
  }

  ngOnInit(): void {

  }

  selectPeriod(label: string){
    this.store.dispatch(new SelectPeriod(label));
    this.showRangeSelector = false;
    if (this.dropdown){
      this.dropdown.hide();
    }

  }

  editCustom(){
    this.showRangeSelector = true;


    // dp.show();
  }

  onRangeChange(range: [Date, Date]){

    if (this.showRangeSelector && range && range[0] && range[1]){
      
      range[0].setHours(0, 0, 0);
      range[1].setHours(23, 59, 59);
      this.store.dispatch(new SetCustomPeriod({
        start: range[0],
        end: range[1]
      }));
      this.dropdown.hide();
      this.showRangeSelector = false;
      this.customRange = range;
    }
  }
}
