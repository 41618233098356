<ng-select 
  [ngModel]="selected" 
  [items] = "items"
  [ngClass]="inputClass"
  bindValue="tzCode"
  bindLabel="label"
  groupBy="group"
  [placeholder]="defaultTz"
  (ngModelChange)="setValue($event)"
  [clearable]="true"
  >
  <ng-template ng-label-tmp let-item="item">
    {{item.tzCode}}
  </ng-template>
</ng-select>