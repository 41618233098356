import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store, NgxsAfterBootstrap } from '@ngxs/store';
import { BANNED_ACCOUNT_CODES } from 'src/app/app.const';
import { SilentError } from 'src/app/errors/studio-errors.model';
import { IMenuItem } from '../../../private/layout/menu/menu-item.model';
import { StudioApiService } from '../../api/studio-api.service';
import { IBasicAccount } from '../../models';
import { LoggedOut, Logout } from '../auth/auth.actions';
import { TabsMessage } from '../tabs-bus/tabs-bus.actions';
import { AccountsUpdated, AddAccount, GetAccount, RemoveAccount, SetAccount, SetMenu, StartMaintenance, StopMaintenance } from './core.actions';

const FORBID_DELAY = 20000;
export interface CoreStateModel {
  accountcode: string;
  menu: IMenuItem[];
  account_codes: string[];
  accounts: {
    [accountcode: string]: IBasicAccount
  };
  accounts_retrieved: {
    [accountcode: string]: number;
  };
  forbidden_accountcodes: string[];
  maintenance?: boolean;
  maintenance_last_check?: number;
}

const defaults: CoreStateModel = {
  accountcode: null,
  menu: [],
  account_codes: [],
  accounts: {},
  accounts_retrieved: {},
  forbidden_accountcodes: []
};

@State<CoreStateModel>({
  name: 'core',
  defaults
})
@Injectable()
export class CoreState implements NgxsAfterBootstrap {
  constructor(
    protected store: Store,
    protected router: Router,
    protected zone: NgZone,
    protected api: StudioApiService,
    // protected appSkin: AppSkinService
  ) { }

  @Selector()
  static getAccounts(state: CoreStateModel): { [accountcode: string]: IBasicAccount } {
    return state.accounts;
  }

  @Selector()
  static getAccountsArray(state: CoreStateModel): IBasicAccount[] {
    return state.account_codes.map(a => state.accounts[a]);
  }
  @Selector()
  static getAccountCodes(state: CoreStateModel): string[] {
    return state.account_codes;
  }

  @Selector()
  static getCurrentAccount(state: CoreStateModel): IBasicAccount {
    return state.accounts[state.accountcode];
  }
  @Selector()
  static getCurrentAccountCode(state: CoreStateModel): string {
    return state.accountcode;
  }

  @Selector()
  static getMenu(state: CoreStateModel): IMenuItem[] {
    return state.menu;
  }

  @Selector()
  static getForbiddenAccountCodes(state: CoreStateModel): string[] {
    return state.forbidden_accountcodes;
  }

  @Selector()
  static isInMaintenance(state: CoreStateModel): boolean {
    return state.maintenance;
  }

  @Selector()
  static getMaintenanceLastCheck(state: CoreStateModel): number {
    return state.maintenance_last_check;
  }

  @Action(SetAccount)
  setAccount(ctx: StateContext<CoreStateModel>, { payload }: SetAccount) {
    if (ctx.getState().account_codes.indexOf(payload) > -1) {
      // this.appSkin.switchTheme(ctx.getState().accounts[payload].domain_name);
      return ctx.patchState({ accountcode: payload });
    }
  }

  @Action(SetMenu)
  setMenu(ctx: StateContext<CoreStateModel>, { payload }: SetMenu) {
    return ctx.patchState({ menu: payload });
  }

  @Action(GetAccount)
  getAccount(ctx: StateContext<CoreStateModel>, { payload }: GetAccount) {
    const st = ctx.getState();

    if (BANNED_ACCOUNT_CODES.indexOf(payload.accountcode) > -1) {
      throw new SilentError('BANNED ACCOUNT CODE');
    }
    // don't try to get account again if we tried less than 20 seconds ago
    if (st.forbidden_accountcodes.indexOf(payload.accountcode) > -1 &&
      Date.now() - st.accounts_retrieved[payload.accountcode] > FORBID_DELAY) {
      // console.error(payload, st);
      return Promise.resolve(null).then(() => {
        throw new Error(`Trying to get account ${payload.accountcode} multiple times`);
      });
    }

    // console.log("Investigating / account /");
    const static_hack = { account: {
      accountcode: "u01",
      decommissioned: false,
      domain_name: "Indigo",
      flags: {},
      is_domain: false,
      name: "Goco",
      tags: [""]}
      };
    ctx.dispatch(new AddAccount(static_hack.account));
    

  }

  @Action(AddAccount)
  addAccount(ctx: StateContext<CoreStateModel>, { payload }: AddAccount) {
    // console.warn('ADD ACCOUNT action', payload);

    const newAccounts: IBasicAccount[] = Array.isArray(payload) ? payload : [payload];
    const newAccouncodes = [...ctx.getState().account_codes];
    const newAccountsMap = { ...ctx.getState().accounts };
    const newAccountsRetrieved = { ...ctx.getState().accounts_retrieved };
    const now = Date.now();
    // newAccounts.forEach(a => {
    //   if (newAccouncodes.indexOf(a.accountcode) === -1) {
    //     newAccouncodes.push(a.accountcode);
    //   }
    //   newAccountsMap[a.accountcode] = cleanAccount(a);
    //   newAccountsRetrieved[a.accountcode] = now;
    // });
    // // console.log('ADD ACCOUNT', payload, newAccounts, newAccouncodes, newAccountsMap, newAccountsRetrieved);
    // newAccouncodes.sort((a, b) => {
    //   return newAccountsMap[a].name.localeCompare(newAccountsMap[b].name);
    // });


    const newState = {
      account_codes: newAccouncodes,
      accounts: newAccountsMap,
      accounts_retrieved: newAccountsRetrieved
    };

    ctx.dispatch(new TabsMessage({
      state: 'core',
      action: 'patch',
      data: newState
    }));
    return ctx.patchState(newState);
  }

  @Action(RemoveAccount)
  removeAccount(ctx: StateContext<CoreStateModel>, { payload }: RemoveAccount) {
    const newAccounts: IBasicAccount[] = Array.isArray(payload) ? payload : [payload];
    const newAccouncodes = [...ctx.getState().account_codes];
    const newAccountsMap = { ...ctx.getState().accounts };
    const newAccountsRetrieved = { ...ctx.getState().accounts_retrieved };
    newAccounts.forEach(a => {
      const idx = newAccouncodes.indexOf(a.accountcode);
      if (idx > -1) {
        newAccouncodes.splice(idx, 0);
      }
      delete newAccountsMap[a.accountcode];
      delete newAccountsRetrieved[a.accountcode];
    });
    const newState = {
      account_codes: newAccouncodes,
      accounts: newAccountsMap,
      accounts_retrieved: newAccountsRetrieved
    };
    ctx.patchState(newState);
    ctx.dispatch(new TabsMessage({
      state: 'core',
      action: 'patch',
      data: newState
    }));
    if (newAccouncodes.indexOf(ctx.getState().accountcode) === -1) {
      this.zone.run(() => {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      });
    }
  }

  @Action([Logout, LoggedOut])
  logout(ctx: StateContext<CoreStateModel>) {
    return ctx.setState(defaults);
  }

  @Action(AccountsUpdated)
  accountsUpdated(ctx: StateContext<CoreStateModel>) {
    const newState = JSON.parse(localStorage.getItem('core')) as CoreStateModel;
    if (newState) {
      ctx.patchState({
        accounts: newState.accounts,
        account_codes: newState.account_codes,
        accounts_retrieved: newState.accounts_retrieved
      });
      if (newState.account_codes.indexOf(ctx.getState().accountcode) === -1) {
        this.zone.run(() => {
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        });
      }
    }

  }

  ngxsAfterBootstrap(ctx: StateContext<CoreStateModel>) {
    const current = ctx.getState().accountcode;
    if (current) {
      ctx.dispatch(new SetAccount(current));
    }

  }

  @Action(StartMaintenance)
  startMaintenance(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      maintenance_last_check: Date.now(),
      maintenance: true
    });
    this.zone.run(() => {
      this.router.navigate(['/', 'maintenance']);
    });
  }

  @Action(StopMaintenance)
  stopMaintenance(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      maintenance_last_check: undefined,
      maintenance: undefined
    });
  }


}


function cleanAccount(a: IBasicAccount): IBasicAccount {
  return {
    ...a,
    name: a.name || '',
    tags: a.tags.map(t => t.trim()).filter(t => t)
  };
}
