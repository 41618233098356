<div class="landing-brand ">
  <a (click)="brandClick()"></a>
</div>
<ng-container *transloco="let t">
  <div class="landing-container">
    <h3 class="landing-head">{{title}}</h3>
    <ng-content select=".landing-body">

    </ng-content>    
  </div>
  <ng-content select=".landing-postfix">

  </ng-content>    
  <div class="landing-lang text-center">
    <ng-container *ngFor="let lang of langService.transloco.getAvailableLangs()">
      <a class="text-muted" *ngIf="lang.id !== langService.lang"
        (click)="switchLang(lang.id)">{{lang.label}}</a>
    </ng-container>
  </div>
</ng-container>