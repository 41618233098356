import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './config/app-config.service';
import { IDomainConfig } from './config/domains-skin.model';
import { DomainConfigs } from './config/domains-skin.const';

const elId = 'studio-theme';


@Injectable({
  providedIn: 'root'
})
export class AppSkinService {
  // FRO
  // static readonly themes = ['ubity', 'xittel', 'telox', 'telenet', 'cooptel', 'vocem', 'ordinacoeur', 'nanaimo', 'quebec'];

  static readonly themes = ['ubity'];

  // FRO
  // static readonly ubityDomains = ['ubity', 'nanaimo', 'quebec'];
  static readonly ubityDomains = ['ubity'];

  static readonly substitution = {
    ubity: 'indigo'
  };

  protected theme: string;

  constructor(
    protected config: AppConfigService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.switchTheme();
  }



  // If it is needed to know the current theme eventually outside of the service
  // tslint:disable-next-line: variable-name
  protected _theme$ = new ReplaySubject<string>();

  get theme$() {
    return this._theme$.asObservable();
  }

  get domainConfig$(): Observable<IDomainConfig> {
    return this.theme$.pipe(
      map(t => this.domainConfig),
    );
  }

  get domainConfig(): IDomainConfig {
    // const realTheme = this.theme === 'default' ? this.config.getDefaultTheme() : this.theme;
    const realTheme = this.theme;
    return {
      name: realTheme, footer: [],
      // status_page: DomainConfigs.ubity.status_page,
      ...DomainConfigs[realTheme]
    };
  }

  get defaultStatusPage() { return DomainConfigs.ubity.status_page; }

  switchTheme(newTheme?: string) {
    if (!newTheme || newTheme === 'default' || AppSkinService.themes.indexOf(newTheme) === -1) {
      newTheme = this.config.getDefaultTheme();
    }

    if (newTheme !== this.theme) {

      this.theme = newTheme;
      this._theme$.next(newTheme);
      const realTheme = AppSkinService.substitution[newTheme] ? AppSkinService.substitution[newTheme] : newTheme;
      // const realTheme = newTheme === 'default' ? this.config.getDefaultTheme() : newTheme;

      this.loadStyle(`assets/themes/${realTheme}/theme.css?v=` + this.config.getAppVersion());
    }
  }


  protected loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      elId
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = elId;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
    this.switchFavicon();
  }

  protected switchFavicon() {
    const favicon = this.domainConfig.favicon || 'favicon.ico';
    this.document.getElementById('appFavicon').setAttribute('href', favicon);


  }
}
