import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths, subWeeks, toDate } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { BuildPeriods, PRESET_PERIODS, SelectPeriod, SetCustomPeriod, SetPeriodTimezone } from './period.actions';


export interface PeriodStateModel {
  periods: {
    [label: string]: { start: Date, end: Date }
  };
  selected?: string;
  timezone?: string;
  _last_update: number;
}

const defaults = {
  periods: {},
  _last_update: 0,
  selected: 'last_7_days'
};

@State<PeriodStateModel>({
  name: 'period',
  defaults
})
@Injectable()
export class PeriodState implements NgxsAfterBootstrap {


  @Selector()
  static getTimezone(state: PeriodStateModel) {
    return state.timezone;
  }

  @Selector()
  static getSelected(state: PeriodStateModel) {
    return state.selected;
  }

  @Selector()
  static getSelectedPeriod(state: PeriodStateModel) {
    return state.periods[state.selected];
  }

  @Selector()
  static getCustomRange(state: PeriodStateModel) {
    const custom = state.periods.custom;
    if (custom){
      return [custom.start, custom.end];
    }
    return '';
  }
  @Selector()
  static getSelectedPeriodForApi(state: PeriodStateModel) {
    if (state.selected !== 'custom' || !state.timezone) {
      return state.periods[state.selected];
    }
    const retval = state.periods[state.selected];
    return {
      start: zonedTimeToUtc(retval.start, state.timezone),
      end: zonedTimeToUtc(retval.end, state.timezone),
    };
  }

  @Selector()
  static getFullSelectedPeriod(state: PeriodStateModel) {
    return { selected: state.selected, period: state.periods[state.selected] };
  }

  @Action(SelectPeriod)
  selectPeriod(ctx: StateContext<PeriodStateModel>, { payload }: SelectPeriod) {
    ctx.patchState({
      selected: payload
    });
  }

  @Action(SetCustomPeriod)
  setCustom(ctx: StateContext<PeriodStateModel>, { payload }: SetCustomPeriod) {
    ctx.patchState({
      selected: 'custom',
      periods: {
        ...ctx.getState().periods,
        custom: payload
      }
    });
  }
  @Action(SetPeriodTimezone)
  setPeriodTimezone(ctx: StateContext<PeriodStateModel>, { payload }: SetPeriodTimezone) {
    ctx.patchState({
      timezone: payload,
    });
    if (ctx.getState().selected === 'custom') {
      ctx.patchState({
        periods: {
          ...ctx.getState().periods,
          custom: { ...ctx.getState().periods.custom }
        }
      });
    }
    this.buildPeriods(ctx);
  }
  ngxsAfterBootstrap(ctx?: StateContext<any>): void {
    this.buildPeriods(ctx);
  }


  @Action(BuildPeriods)
  buildPeriods(ctx: StateContext<PeriodStateModel>) {

    let today = new Date();

    const tz = ctx.getState().timezone;

    if (tz) {
      today = utcToZonedTime(today, tz);
    }


    const newPeriods = PRESET_PERIODS.reduce((r, c) => {
      switch (c) {
        case 'today':
          r[c] = { start: toDate(today), end: today };
          break;
        case 'yesterday':
          const yesterday = subDays(today, 1);
          r[c] = { start: yesterday, end: toDate(yesterday) };
          break;
        case 'this_week':
          r[c] = { start: startOfWeek(today), end: endOfWeek(today) };
          break;
        case 'last_week':
          r[c] = { start: subWeeks(startOfWeek(today), 1), end: subWeeks(endOfWeek(today), 1) };
          break;
        case 'this_month':
          r[c] = { start: startOfMonth(today), end: endOfMonth(today) };
          break;
        case 'last_month':
          r[c] = { start: subMonths(startOfMonth(today), 1), end: subMonths(endOfMonth(today), 1) };
          break;
        case 'last_7_days':
          r[c] = { start: subDays(today, 7), end: today };
          break;
        case 'last_30_days':
          r[c] = { start: subDays(today, 30), end: today };
          break;
      }
      if (r[c]) {
        r[c].start.setHours(0, 0, 0);
        r[c].end.setHours(23, 59, 59);
      }
      return r;
    }, {});

    return ctx.patchState({
      periods: {
        // ...ctx.getState().periods,
        custom: ctx.getState().periods.custom || undefined,
        ...newPeriods
      },
      _last_update: Date.now()
    });
  }


}
