import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ColumnMode, TableColumn } from '@swimlane/ngx-datatable';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IGenericQuery } from '../../models';


export interface StudioTableColumn extends TableColumn {
  label?: string;
  class?: string | ((data: any) => string | any);
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends OnDestroyMixin implements OnInit, OnChanges, AfterViewInit {

  @Input() header: string;
  @Input() query: IGenericQuery = {
    _limit: 25,
    _offset: 0
  };
  @Input() count = 0;
  @Input() loading = false;
  @Input() items: any[];

  @Input() pagination = true;

  @Input() display: 'table' | 'custom' = 'table';
  @Input() customEmpty: TemplateRef<any>;

  @Input() columns: StudioTableColumn[] = [];

  @Input() batchSizes = [10, 25, 50, 100];

  @Output() queryChanged = new EventEmitter<IGenericQuery>();

  @Input() headerPrefix = '';

  @Input() trackByProp: string;

  get pageNum() { return (this.query._offset / this.query._limit) + 1; }


  maxPages = 5;
  form = this.fb.group({
  });

  ColumnMode = ColumnMode;

  realColumns: TableColumn[] = [];

  @ViewChild('hdrTpl', { static: false }) hdrTpl: TemplateRef<any>;


  get currentSort() {
    return this.query && this.query._order ?
      [{
        prop: this.query._order.substr(1),
        dir: this.query._order.substr(0, 1) === '-' ? 'desc' : 'asc'
      }] : undefined;
  }

  constructor(
    protected fb: FormBuilder,
    protected breakpointsObserver: BreakpointObserver,
    protected transloco: TranslocoService

  ) { super(); }

  ngOnInit(): void {

    combineLatest([
      this.transloco.events$.pipe(
        filter(e => e.type === 'translationLoadSuccess')
      ),
      this.transloco.langChanges$
    ]).pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe((x) => {
      // console.info('Lang ', x);
      this.setColumns();
      if (this.items) {
        this.items = [...this.items];
      }

    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.query && changes.query.currentValue && !this.loading) {
      this.form.patchValue(changes.query.currentValue, {
        emitEvent: false
      });
    }
    if (changes.columns) {
      this.setColumns();
    }
  }

  ngAfterViewInit() {
    this.setColumns();
  }

  protected setColumns() {
    this.realColumns = this.columns.map((c: StudioTableColumn) => {
      const newC: TableColumn = {
        sortable: true,
        flexGrow: 1,
        ...c
      };

      const defaultClass = `col-${newC.prop}`;
      const classType = typeof c.class;

      ['headerClass', 'cellClass'].forEach(p => {
        if (newC[p]) {
          if (typeof newC[p] === 'function') {
            const oldFn = newC[p];
            if (c.class) {
              if (typeof c.class === 'function') {
                const genericFn = c.class;
                newC[p] = (data: any) => genericFn(data) + ` ${defaultClass} ` + oldFn(data);
              } else {
                newC[p] = (data: any) => oldFn(data) + ` ${defaultClass} ${c.class}`;
              }
            } else {
              newC[p] = (data: any) => `${defaultClass} ` + oldFn(data);
            }

          } else if (typeof newC[p] === 'string') {
            if (c.class) {
              if (typeof c.class === 'function') {
                const genericFn = c.class;
                newC[p] = (data: any) => genericFn(data) + ` ${defaultClass} ${newC[p]}`;
              } else {
                newC[p] = `${defaultClass} ${c.class} ${newC[p]}`;
              }
            } else {
              newC[p] = `${defaultClass} ${newC[p]}`;
            }
          }
        } else {
          if (c.class) {
            if (typeof c.class === 'function') {
              const genericFn = c.class;
              newC[p] = (data: any) => genericFn(data) + ` ${defaultClass}`;
            } else {
              newC[p] = `${defaultClass}  ${c.class}`;
            }
          } else {
            newC[p] = defaultClass;
          }
        }
      });

      newC.name = this.transloco.translate(c.name ? c.name : this.headerPrefix + c.prop);


      // if (!c.name && !c.headerTemplate) {
      //   newC.headerTemplate = this.hdrTpl;
      // }
      // console.log('column is ', newC, 'from', c);
      return newC;
    });
  }

  changeLimit(l: number) {

    const newOffset = this.query._offset - (this.query._offset % l);

    // console.log('NEW offset', newOffset);
    this.queryChanged.emit({ ...this.query, _limit: l, _offset: newOffset });
  }

  pageChanged(event: { page: number, itemsPerPage: number }) {
    const newOffset = (event.page - 1) * event.itemsPerPage;
    if (newOffset !== this.query._offset) {
      // console.warn('PAGE CHANGED', event, this.query._offset, this.query._limit, newOffset);
      this.queryChanged.emit({ ...this.query, _offset: newOffset });
    }


  }

  offsetChanged(offset: number) {
    this.queryChanged.emit({ ...this.query, _offset: offset });
  }

  onSort(event: { sorts, column, newValue, oldValue }) {

    const newOrder = (event.sorts[0].dir === 'asc' ? '+' : '-') + event.sorts[0].prop;
    // console.log('SORT EVENT', event, 'was', this.query._order, 'will be', newOrder);
    if (newOrder !== this.query._order) {
      this.queryChanged.emit({ ...this.query, _order: newOrder });
    }
  }


}
