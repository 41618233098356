import { Directive, Host, HostBinding, Inject, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';


export function controlPath(name: string, parent: ControlContainer): string[] {
  // tslint:disable-next-line:no-non-null-assertion
  return [...parent.path!, name];
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '.form-control,.form-check-input,.custom-control-input,.custom-select'
})
export class FormControlValidateDirective {
  @Input()
  formControlName: string;
  @Input()
  formControl: string;

  @Input()
  showValid: false;

  @HostBinding('class.is-valid')
  get validClass() {
    if (!this.control || !this.showValid) {
      return false;
    }
    return (
      this.control.valid &&
      (this.control.touched || this.control.dirty)
    );
  }

  @HostBinding('class.is-invalid')
  get invalidClass() {
    if (!this.control) {
      return false;
    }
    return (
      this.control.invalid &&
      this.control.touched &&
      this.control.dirty
    );
  }

  get path() {
    return controlPath(this.formControlName, this.parent);
  }

  get control(): FormControl {
    return this.formDirective && this.formDirective.getControl(this);
  }

  get formDirective(): any {
    return this.parent ? this.parent.formDirective : null;
  }


  constructor(
    // this value might be null, but we union type it as such until
    // this issue is resolved: https://github.com/angular/angular/issues/25544
    @Optional()
    @Host()
    @SkipSelf()
    private parent: ControlContainer
  ) {

  }
}
