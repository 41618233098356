import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Store } from '@ngxs/store';
import { take, filter, tap, catchError } from 'rxjs/operators';
import { AuthState } from '../state/auth/auth.state';
import { Logout } from '../state/auth/auth.actions';
import { Studio3ApiError } from '../api';
import { OidcSecurityService } from 'angular-auth-oidc-client';

const insecureEndpoints = ['/auth/login', '/auth/logout'];
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  baseUri;
  ready = false;
  prefix;
  constructor(
    public config: AppConfigService,
    public store: Store,
    protected oidcSecurityServices: OidcSecurityService
  ) {
    this.config.isReady$.pipe(
      filter(r => r),
      tap((r) => {
        this.ready = r;
        this.baseUri = this.config.getApiUri();
        this.prefix = this.config.getAuthPrefix();
      }),
      take(1)
    ).subscribe();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.ready && request.url.startsWith(this.baseUri)
      && insecureEndpoints.findIndex(e => request.url.endsWith(e)) === -1
      // && request.headers.has('Access-Control-Allow-Credentials')
    ) {
      // const authToken = this.store.selectSnapshot(AuthState.token);
      console.log("Will ask for token");
      const token1 = this.oidcSecurityServices.getIdToken();
      // console.log("ID Token:", token1);
      const token = this.oidcSecurityServices.getToken();
      // console.log("Token:", token);
      const authenticatedReq = request.clone({
        // headers: request.headers.set('Authorization', `authtkt ${authToken}`)
        headers: request.headers.set('Authorization', `${this.prefix} ${token}`)
      });
      return next.handle(authenticatedReq).pipe(
        catchError((error) => {
          // Disconnect
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.store.dispatch(new Logout());
            throw new Studio3ApiError(error, true);
          }
          throw error;

        }));
    }
    return next.handle(request);
  }
}
