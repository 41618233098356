import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxLoadingModule } from 'ngx-loading';
import { AddressComponent } from './components/address/address.component';
import { ApiErrorComponent } from './components/api-error/api-error.component';
import { BasicMessageComponent } from './components/basic-message/basic-message.component';
import { EmptyComponent } from './components/empty/empty.component';
import { LandingContainerComponent } from './components/landing-container/landing-container.component';
import { ListComponent } from './components/list/list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalRouteComponent } from './components/modal-route/modal-route.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PsswdInputComponent } from './components/psswd-input/psswd-input.component';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { DisabledDelayDirective } from './directives/disable-delay.directive';
import { InputPasswordDirective } from './directives/input-password.directive';
import { ResponsiveTabsDirective } from './directives/responsive-tabs.directive';
import { SmartLinkWithHrefDirective } from './directives/smart-link.directive';
import { StudioFormsModule } from './forms/forms.module';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';
import { DomainPipe } from './pipes/domain.pipe';
import { EncodeUriPipe } from './pipes/encode-uri.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PhoneOrExtPipe } from './pipes/phone-or-ext.pipe';
import { PeriodSelectorComponent } from './components/period-selector/period-selector.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DurationPipe } from './pipes/duration.pipe';
import { TimezoneSelectorComponent } from './components/timezone-selector/timezone-selector.component';



// TODO : Split shared module so that only eagerly needed stuff remains in it and put the rest in a lazyly shared module

@NgModule({
  declarations: [
    EmptyComponent, PaginationComponent, ListComponent, InputPasswordDirective,
    PsswdInputComponent,
    SmartLinkWithHrefDirective,
    ModalConfirmComponent,
    ModalRouteComponent,
    EncodeUriPipe,
    SmartSelectComponent,
    LoadingComponent,
    BasicMessageComponent,
    ApiErrorComponent,
    ResponsiveTabsDirective,
    DisabledDelayDirective,
    DomainPipe,
    LandingContainerComponent,
    AddressComponent,
    PhoneNumberPipe,
    PhoneOrExtPipe,
    PeriodSelectorComponent,
    DurationPipe,
    TimezoneSelectorComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxLoadingModule,
    AlertModule,
    NgSelectModule,
    StudioFormsModule,
    TranslocoLocaleModule,
    BsDropdownModule,
    BsDatepickerModule
  ],
  exports: [
    CommonModule,
    TranslocoModule,
    EmptyComponent,
    PaginationComponent,
    PsswdInputComponent,
    InputPasswordDirective,
    ListComponent,
    SmartLinkWithHrefDirective,
    ModalConfirmComponent,
    ModalRouteComponent,
    EncodeUriPipe,
    SmartSelectComponent,
    LoadingComponent,
    BasicMessageComponent,
    StudioFormsModule,
    ApiErrorComponent,
    ResponsiveTabsDirective,
    TranslocoLocaleModule,
    DisabledDelayDirective,
    DomainPipe,
    LandingContainerComponent,
    AddressComponent,
    PhoneNumberPipe,
    PhoneOrExtPipe,
    PeriodSelectorComponent,
    DurationPipe,
    TimezoneSelectorComponent
  ]
})
export class SharedModule { }

