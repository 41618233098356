import { Injectable } from '@angular/core';
import { HashMap, TranslocoMissingHandler, TranslocoMissingHandlerData } from '@ngneat/transloco';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MissingTranslationService implements TranslocoMissingHandler{

  protected misses = new Subject<{key: string, lang: string}>();
  constructor(
    // protected errorHandler: ErrorHandler
    ) {
  }

  handle(key: string, data: TranslocoMissingHandlerData, params?: HashMap<any>) {
    if (key.startsWith('country.') || key.startsWith('province.')){
      return key.substr(key.lastIndexOf('.') + 1);
    }
    if (key.startsWith('error.')){
      return key.substr('error.'.length);
    }
    this.misses.next({
      key, lang: data.activeLang
    });
    // this.errorHandler.handleError(new Error(`Missing Translation ${key}`));

    return key;
  }

  getMisses(){
    return this.misses.asObservable();
  }


}
