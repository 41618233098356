import { AfterContentInit, AfterViewInit, Component, ContentChild, Input, OnInit, OnChanges } from '@angular/core';
import { InputPasswordDirective } from '../../directives/input-password.directive';
@Component({
  selector: 'app-psswd-input',
  templateUrl: './psswd-input.component.html',
  styleUrls: ['./psswd-input.component.scss']
})
export class PsswdInputComponent implements OnInit, AfterContentInit, OnChanges {

  @Input() showPassword = false;

  @ContentChild(InputPasswordDirective) input: InputPasswordDirective;
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {

    this.setupInput();
  }
  ngAfterContentInit() {
    // console.log('After content init');
    this.setupInput();
  }


  toggle() {
    this.showPassword = !this.showPassword;
    this.setupInput();
  }

  setupInput() {
    // console.log('setup input', this.input);
    if (this.input) {
      this.input.showPassword = this.showPassword;
    }
  }



}
