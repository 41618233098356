import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from 'src/app/config/app-config.service';
import { filter, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudioApiService {

  baseUri;
  ready = false;
  constructor(
    protected http: HttpClient,
    public config: AppConfigService,
  ) {

    this.config.isReady$.pipe(
      filter(r => r),
      tap((r) => { this.ready = r; this.baseUri = this.config.getApiUri(); }),
      take(1)
    ).subscribe();
  }


  _getUrl(uri: string, version?: string) {
    return this.baseUri + (uri.startsWith('/') ? uri : '/' + uri);
  }

  get<T>(uri: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.get<T>(
      this._getUrl(uri),
      options
    );
  }

  post<T>(uri: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.post<T>(
      this._getUrl(uri),
      body, options
    );
  }

  put<T>(uri: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'body';
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.put<T>(
      this._getUrl(uri),
      body,
      options
    );
  }

  patch<T>(uri: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.patch<T>(
      this._getUrl(uri),
      body,
      options,
    );
  }


  delete<T>(uri: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.delete<T>(
      this._getUrl(uri),
      options
    );
  }

}
