// Used as a placeholder for routes when we don't know yet to which account we should redirect
export const ROUTE_ACCOUNT_PLACEHOLDER = '__';
// Sent by control2 when (All Customers) is selected
export const ROUTE_ACCOUNT_ALL = 'ALL';
// Sent by control2 when (Unassigned)) is selected
export const ROUTE_ACCOUNT_UNASSIGNED = 'unassigned';

export const BANNED_ACCOUNT_CODES = [
  ROUTE_ACCOUNT_PLACEHOLDER,
  ROUTE_ACCOUNT_ALL,
  ROUTE_ACCOUNT_UNASSIGNED,
  // The following will cause an invalid route as accountcodes are used in URI
  '.',
  '..'
];
