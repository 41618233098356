import { Injectable } from '@angular/core';
import { SentryErrorHandlerService } from './sentry-error-handler.service';
import { filter, take } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { Store } from '@ngxs/store';
import { AuthState } from '../shared/state/auth/auth.state';
import { CoreState } from '../shared/state/core/core.state';

@Injectable({
  providedIn: 'root'
})
export class SentryScopeHelperService {

  constructor(
    protected errorHandler: SentryErrorHandlerService,
    protected store: Store,
  ) {
    this.errorHandler.ready$.pipe(
      filter(r => r),
      take(1)
    ).subscribe(() => {
      this.setup();
    });
    // console.log('SentryScopeHelperService');
  }

  setup() {
    this.store.select(AuthState.getUser).subscribe(user => {
      Sentry.configureScope((scope) => {
        if (user && user.id) {
          scope.setUser({ id: String(user.id), email: user.email });
        } else {
          scope.setUser({});
        }

      });
    });

    this.store.select(CoreState.getCurrentAccountCode).subscribe(ac => {
      Sentry.configureScope((scope) => {
        scope.setTag('accountcode', ac);
      });
    });
  }
}
