<ng-select class="form-control" 
  [disabled]="disabled"
  
  [ngModel]="value"
  (ngModelChange)="setValue($event)" [clearable]="clearable"
  [placeholder]="placeholder" [items]="results$ | async" [bindValue]="idProp"
  [bindLabel]="labelProp" [markFirst]="markFirst" (close)="onTouched()"
  (blur)="onTouched()" [loading]="source.loading$ | async" loadingText="..."
  [typeahead]="typeahead" [multiple]="multiple"
  [selectOnTab]="selectOnTab"
  (search)="selectSearch.emit($event)"
  (clear)="selectClear.emit()"
  (change)="selectChange.emit($event)"
  [editableSearchTerm]="editableSearchTerm"
  [isOpen]="isOpen"
  [loadingText]="loadingText"
  [clearAllText]="clearAllText || ('actions.clear' | transloco)"
  >


  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngTemplateOutlet="displayItem; context: {item: item}"></ng-container>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <ng-container *ngTemplateOutlet="displayItem; context: {item: item}"></ng-container>
  </ng-template>

</ng-select>

<ng-template #displayItem let-item="item">
  <ng-container *ngIf="addressProp && item[addressProp]">
      <i class="flag-icon mr-2" [ngClass]="'flag-icon-'+(item[addressProp].country | lowercase)"></i>
  </ng-container>
  <ng-container *ngIf="labelPropi18n else directLabel">
    <ng-container *ngIf="i18nSuffix$ | async as suffix else directLabel">      
        {{item[labelProp+suffix] || item[idProp]}}      
    </ng-container>
  </ng-container>
  <ng-template #directLabel>{{item[labelProp]}}</ng-template>
</ng-template>