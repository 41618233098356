import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from '../state/core/core.state';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate, CanActivateChild {

  constructor(
    protected store: Store,
    protected router: Router){

  }

  isInMaintenance(next: ActivatedRouteSnapshot){
    const inMaintenance = this.store.selectSnapshot(CoreState.isInMaintenance);
    if (next.data.is_maintenance){
      return inMaintenance || this.router.createUrlTree(['/']);
    }
    return !inMaintenance || this.router.createUrlTree(['/', 'maintenance']);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isInMaintenance(next);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isInMaintenance(next);
  }

}
