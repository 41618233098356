import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'domain'
})
export class DomainPipe implements PipeTransform {

  transform(value: string): unknown {
    if (value && value.length){
      return `@${value}`;
    }
    return value;
  }

}
