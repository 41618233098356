import { Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { IAddress } from '../../models';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    { provide: TRANSLOCO_SCOPE, useValue: 'country', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'province', multi: true }
  ]
})
export class AddressComponent implements OnInit {

  @Input() address: IAddress;
  constructor() { }

  ngOnInit(): void {
  }

}
