import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule, TRANSLOCO_MISSING_HANDLER, TRANSLOCO_LOADING_TEMPLATE
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MissingTranslationService } from './missing-translation.service';


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    const idx = lang.indexOf('/');
    let path = lang;
    let scope = '';
    if (idx > -1){
      scope = lang.substr(0, idx);
      path = lang.replace('/', `/${scope}-`);
    }
    return this.http.get<Translation>(`assets/i18n/${path}.json?v=${environment.version}`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  imports: [
    TranslocoLocaleModule.init({
      // langToLocaleMapping: {
      //   en: 'en-CA',
      //   fr: 'fr-CA'
      // }
    })
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'fr', label: 'français' }
        ],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang:  'en',
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: false // If true when lang is FR both lang files will be fetched
        },
        flatten: {
          aot: environment.production
        }

      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingTranslationService }
  ],
  declarations: []
})
export class TranslocoRootModule { }
