import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../config/app-config.service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class StudioErrorHandlerService implements ErrorHandler {
  debug = !environment.production;
  constructor(protected appConfig: AppConfigService){
    this.appConfig.isReady$.pipe(
      filter(r => r),
      take(1)
    ).subscribe(() => {
      this.debug = this.appConfig.getDebug();
    });
  }
  handleError(error: any) {
    if (error && error.isSilent ){

      if (this.debug){
        console.error('A Silent ERROR Occured (will not be thrown in production)!', error);
        throw error;
      }
      return ;
    }

    console.error('An error occured', error);

    // Make sure to rethrow the error so Angular can pick it up
    throw error;
  }
}
