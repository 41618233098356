<ng-container *transloco="let t">
  <alert *ngIf="error && show" type="danger" [dismissible]="dismissible"
    (onClose)="dismiss()" [ngClass]="errorClass">
    <div class="d-flex align-items-center">
      <i class="mr-2" [ngClass]="icon"></i>
      <div>
        <ng-content select=".error-prepend"></ng-content>
        <span [innerHTML]="t(errorKey, getData(t))"></span>
      </div>
    </div>
  </alert>
</ng-container>