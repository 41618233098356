import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { IGenericQuery } from '../models';
import { StudioHttpParamEncoder } from './api-http-params.encoder';

export interface StudioError {
  code: number;
  body: any;
  msg: string;
}

export function getApiErrorFromHTTP(error: Error): string{

  let msg = 'api.unknown';
  if (error instanceof HttpErrorResponse){
    switch (error.status){
      case 0:
        msg = 'api.unreachable';
      case 500:
        msg = 'api.500';
      case 401:
    }
  }
  return msg;
}

export function cleanListResponseQuery(q: IGenericQuery): IGenericQuery{
  const newQ = {...q};
  // console.warn('Query is ', q, q._order );
  if (q){

    if (q._order && !q._order.startsWith('-') && !q._order.startsWith('+')){
      newQ._order = `+${q._order}`;
    }
  }

  delete (newQ as any).resource;

  // console.warn('CLEANED QUERY', q, newQ, newQ._order );
  return newQ;
}

export function queryToParams(query: IGenericQuery): HttpParams {
  let params = new HttpParams({
    encoder: new StudioHttpParamEncoder()
  });
  if (query) {
    Object.keys(query).forEach(k => {
      if (k !== 'ressource' && k !== 'resource'
        && query[k] !== null && query[k] !== undefined
        && query[k] !== '') {
        if (!Array.isArray(query[k]) || query[k].length) {
          // params = params.append(k, encodeURIComponent(query[k]));
          params = params.append(k, query[k]);
        }

      }
    });
  }

  return params;
}
