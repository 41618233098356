import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IndigoAuthService } from '../auth/auth.service';
import { IIndigoLandingConfig } from '../config/app-config.model';
import { AppConfigService } from '../config/app-config.service';
import { LangService } from '../lang/lang.service';
import { TitleService } from '../shared/services/title.service';

/*

When opening the page you get a big "Goco Control Panel" sign in dialog box.

*/


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],

})
export class LandingComponent extends OnDestroyMixin implements OnInit {

  pageTitle$ = this.title.currentTitle$;
  cfg: IIndigoLandingConfig = this.appConfig.getLandingConfig();
  lang = this.langService.lang;

  constructor(
    public title: TitleService,
    protected authService: IndigoAuthService,
    protected appConfig: AppConfigService,
    protected langService: LangService
  ) {
    super();
    this.langService.lang$.pipe(
      untilComponentDestroyed(this)
    ).subscribe(l => {
      this.lang = l;
    });
  }

  ngOnInit(): void {
    // 9/9/21 Fro - auto sign in
    // changed again on Sept 16th to play with languages urls

    this.authService.authorize(null, this.lang);
  }

  login(){
    // is associated with sign in button
    this.authService.authorize(null, this.lang);
  }

}
