import { Directive, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appDisabledDelay]'
})
export class DisabledDelayDirective implements OnChanges, OnDestroy{

  timeout: Subscription;
  disabledByDelay = false;
  @Input() disabled = false;
  @Input() defaultDelay =  10000;
  // tslint:disable-next-line: no-input-rename
  @Input('appDisabledDelay') lastUpdated: number;
  @HostBinding('attr.disabled')
  get isDisabled( ) {
    return this.disabled || this.disabledByDelay ? 'disabled' : undefined;
  }
  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if (changes.lastUpdated || changes.defaultDelay){
      this.setupTimeout();
    }
  }
  setupTimeout(){
    this.clearTimeout();
    if (!isNaN(this.lastUpdated) && !isNaN(this.defaultDelay)){
      const diff = Date.now() - this.lastUpdated;
      if (diff < this.defaultDelay){
        this.disabledByDelay = true;
        this.timeout = timer(this.defaultDelay - diff).pipe(
          take(1)
        ).subscribe(() => {
          this.disabledByDelay = false;
        });
        return;
      }
    }
    this.disabledByDelay = false;
  }
  clearTimeout(){
    if (this.timeout && !this.timeout.closed){
      this.timeout.unsubscribe();
    }
  }
  ngOnDestroy(){
    this.clearTimeout();
  }

}
