import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { TranslocoService, TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthState } from '../shared/state/auth/auth.state';
import { MissingTranslationService } from './missing-translation.service';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  readonly langs: string[] = (this.transloco.getAvailableLangs() as {
    id: string;
    label: string;
  }[])
    .map(l => l.id);

  protected loadedScopes: {
    [lang: string]: string[]
  } = {};

  // tslint:disable-next-line: variable-name
  protected _scopes = new ReplaySubject<{
    [lang: string]: string[]
  }>();

  protected currentLang = new ReplaySubject<string>();

  loading = false;

  get lang(): string { return this.transloco.getActiveLang(); }

  get scopes$() { return this._scopes.asObservable(); }

  get changes$() {
    return combineLatest([
      this.scopes$,
      this.lang$
    ]);
  }
  constructor(
    public transloco: TranslocoService,
    protected store: Store,
    @Inject(TRANSLOCO_MISSING_HANDLER) protected missingTranslationHandler: MissingTranslationService,
    protected errorHandler: ErrorHandler
  ) {
    this.currentLang.next(this.transloco.getActiveLang());

    
    this.missingTranslationHandler.getMisses().pipe(
      // tap((miss) => console.log(' -- missing translation', miss, this.loadedScopes)),
      
      filter((miss) => {
        // Don't handle error when scopes are loading
        if (this.loadedScopes[miss.lang]){
          const otherLang = miss.lang === 'fr' ? 'en' : 'fr';
          return !this.loadedScopes[otherLang] || this.loadedScopes[miss.lang].length === this.loadedScopes[otherLang].length;
        }
        return false;
      })
    ).subscribe(miss => {
      try {
        this.errorHandler.handleError(new Error(`Missing Translation ${miss.key} (${miss.lang})`));
      } catch (e) {

      }

    });

    this._scopes.next(this.loadedScopes);
    this.transloco.langChanges$.subscribe(l => {
      this.currentLang.next(l);
    });
    this.transloco.events$.pipe(
      filter(e => e.type === 'translationLoadSuccess'),
      map(e => e.payload)
    )
      .subscribe((payload) => {
        if (!this.loadedScopes[payload.langName]) {
          this.loadedScopes[payload.langName] = [];
        }
        this.loadedScopes[payload.langName].push(payload.scope);
        this._scopes.next(this.loadedScopes);
        this.currentLang.next(this.transloco.getActiveLang());
      });
  }

  get lang$() { return this.currentLang.asObservable(); }

  getLangSuffix$(enSuffix = '') {
    return this.currentLang.asObservable().pipe(
      map(l => l === 'fr' ? '_fr' : enSuffix)
    );
  }

  getLangFromBrowser() {
    let newLang = this.transloco.getDefaultLang();
    if (navigator && navigator.languages && navigator.languages.length) {
      const fromBrowser = navigator.languages.find(l => {
        const nl = l.substr(0, 2);
        if (this.langs.indexOf(nl) > -1) {
          newLang = nl;
          return true;
        }
      });
    }
    return newLang;
  }

  setLang(lang: string) {
    this.transloco.setActiveLang(lang);
    localStorage.setItem('indigo_lang', lang);
  }

  getBestLang() {
    let lang: string; // this.store.selectSnapshot(AuthState.getUserLang);

    // TODO check if we have a user and send to default account
    const authState = this.store.selectSnapshot((state) => state.auth || {});

    if (authState.user && authState.preferences.lang) {
      lang = authState.preferences.lang;
    } else {
      const fromStorage = localStorage.getItem('indigo_lang');
      if (this.langs.indexOf(fromStorage) > -1) {
        lang = fromStorage;
      }
    }
    if (!lang) {
      lang = this.getLangFromBrowser();
    }
    return lang;
  }
}
