import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'goco-errors',
  template: `
  <span [ngClass]="errorClass" *ngIf="isDirtyAndTouched && hasErrors">
  <small
  *ngFor="let message of messages()"
    class="text-danger" [innerHTML]="message" ></small>
  </span>
`,
  styleUrls: ['./error.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ErrorComponent implements OnInit, OnChanges, OnDestroy {


  @Input() controlNames: string[];
  // tslint:disable-next-line: no-input-rename
  @Input('bsfErrors')
  errorMessages: {
    [validator: string]: string
  } = {};
  controls: { [name: string]: AbstractControl } = {};
  // tslint:disable-next-line: variable-name
  _controls: AbstractControl[] = [];

  @Input() errorClass = '';


  @Input() showAll = false;
  @Input() withName = false;

  constructor(private parentF: FormGroupDirective) {

  }
  public messages = () => this.getMessages();

  ngOnInit() {
    this.setupControls();
  }
  ngOnDestroy() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.controlNames) {
      this.setupControls();
    }

  }

  setupControls() {
    this.controls = {};
    if (this.controlNames) {
      this.controlNames.forEach(name => {
        this.extractNamesFromGroup(this.parentF.control.get(name), name);
      });
    } else {
      this.controls[''] = this.parentF.control;
      Object.keys(this.parentF.form.controls).forEach(name => {
        this.extractNamesFromGroup(this.parentF.control.get(name), name);
      });
    }
    this._controls = Object.values(this.controls);
  }

  private extractNamesFromGroup(fc: AbstractControl, name: string) {
    if (fc instanceof FormGroup) {
      Object.keys(fc.controls).forEach((n) => {
        this.extractNamesFromGroup(fc.get(n), 'name.' + n);
      });
    } else {
      this.controls[name] = fc;
    }
    return this.controls;

  }


  get hasErrors() {
    return !this.validationDisabled && this._controls.some(c => !c.valid && c.dirty && c.touched);
  }
  get isDirtyAndTouched(): boolean {
    return this._controls.some(c => c.dirty && c.touched);
  }

  get validationDisabled(): boolean {
    return this._controls.filter(c => !c.disabled).length === 0;
  }

  private getMessages(): string[] {

    const messages = [];

    if (!this.isDirtyAndTouched || this.validationDisabled) {
      return messages;
    }


    Object.keys(this.controls).filter(
      (c, i) =>
        !this.controls[c].valid &&
        !!this.controls[c].errors
    ).forEach(controlName => {
      if (!this.showAll && messages.length) {
        return;
      }
      Object.keys(this.controls[controlName].errors).forEach(key => {
        const theKey = this.withName && controlName ? controlName + '.' + key : key;
        const error = this.errorMessages[theKey] ;
        if (!error) {
          return;
        }


        if (messages.indexOf(error) === -1) {
          messages.push(error);
        }

      });
    });
    return messages;
  }

}
