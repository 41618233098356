import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

export interface ModalConfirmConfig {
  title: string;
  description?: string;
  action: any;
  showCheckbox?: boolean;
  showAlert?: boolean;

  alertText?: string;
  alertType?: 'success' | 'info' | 'warning' | 'danger';
  alertIcon?: string;

  cancelText?: string;
  confirmText?: string;
  confirmClass?: string;
}

let nextId = 0;
@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, ModalConfirmConfig {

  readonly id = `confirm-dialog-${nextId++}`;

  title: string;
  description: string;

  action: any;

  showCheckbox = false;
  showAlert = false;

  checkboxText = this.transloco.translate('confirm.checkbox');


  alertText = this.transloco.translate('confirm.irreversible');
  alertType: 'success' | 'info' | 'warning' | 'danger' = 'info';
  alertIcon = 'fas fa-exclamation-triangle';

  cancelText = this.transloco.translate('actions.cancel');
  confirmText = this.transloco.translate('actions.confirm');
  confirmClass = 'btn-brand';

  confirmed = false;

  constructor(
    public modalRef: BsModalRef,
    protected store: Store,
    protected transloco: TranslocoService) { }

  ngOnInit(): void {
  }

  confirm() {
    if (this.action) {
      this.store.dispatch(this.action);
    }
    this.modalRef.hide();
  }

}
