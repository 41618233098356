import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { Studio3ApiError } from '../../api';
import { LangService } from 'src/app/lang/lang.service';
import { OnDestroyMixin, componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

const icons = {
  danger: 'fa fa-exclamation-triangle',
  warning: 'fa fa-exclamation-triangle',
  info: 'fa fa-info-circle',
  success: 'fa fa-check'
};
@Component({
  selector: 'goco-studio-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiErrorComponent extends OnDestroyMixin implements OnInit, OnChanges {


  get icon() { return icons[this.type]; }

  constructor(
    protected langService: LangService,
    protected cd: ChangeDetectorRef
  ) {
    super();

  }

  @Input() errorClass: string;
  @Input() error: Studio3ApiError;
  @Input() dismissible = false;
  @Input() type: 'danger' | 'warning' | 'info' | 'success' = 'danger';

  @Input() specialStatuses: { [status: number]: string } = {};

  @Input() specialPrefix: { [error: string]: string } = {};

  @Input() startsWithPrefix: { [error: string]: string } = {};

  @Input() fields: { [field: string]: string } = {};

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose = new EventEmitter();

  errorKey: string;

  show = true;

  getData(t: (x: string, v?: any) => any) {
    let field = '';
    if (this.error.data && this.error.data.prop) {
      if (Array.isArray(this.error.data.prop)) {
        field = this.error.data.prop.map(f => this.fields[f] ? t(this.fields[f]) : null).filter(f => f).join(', ');
      } else {
        field = t(this.fields[this.error.data.prop]);
      }
    }
    return {
      ...this.error.data,
      field
    };
  }

  ngOnInit(): void {
    this.langService.changes$.pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.error) {
      this.show = true;
      this.setupError();
    }
  }

  setupError() {
    if (!this.error) {
      this.errorKey = null;
      return;
    }
    if (this.specialStatuses && this.specialStatuses[this.error.status]) {
      this.errorKey = this.specialStatuses[this.error.status];
    } else {
      let prefix = 'error.';
      if (this.specialPrefix && this.specialPrefix[this.error.error]) {
        prefix = this.specialPrefix[this.error.error];
      } else if (this.startsWithPrefix) {
        const newP = Object.keys(this.startsWithPrefix).find(p => this.error.error.startsWith(p));
        if (newP) {
          prefix = newP;
        }
      }
      let cleanedError = this.error.error;
      switch (this.error.error) {
        case 'validation.format':
          cleanedError = this.error.error + '.' + this.error.data.val;
          break;
      }
      this.errorKey = prefix + cleanedError;
    }

  }

  dismiss() {
    this.show = false;
    this.onClose.emit();
  }
}
