import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {

    const h = Math.floor(value / 3600);
    let remains = value - h * 3600;
    const m = Math.floor( remains / 60);
    remains = Math.floor(remains - m * 60);

    let retval = '';

    if (h > 0){
      retval += ` ${h}h`;
    }
    if (m > 0){
      retval += ` ${m}m`;
    }

    if (remains > 0){
      retval += ` ${remains}s`;
    }

    return retval.trim();
  }

}
