import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../config/app-config.service';
import { Studio3ApiError } from '../shared/api';
import { catchError, filter, take, tap } from 'rxjs/operators';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  baseUri;
  ready = false;
  constructor(
    public config: AppConfigService
  ) {
    this.config.isReady$.pipe(
      filter(r => r),
      tap((r) => { this.ready = r; this.baseUri = this.config.getApiUri(); }),
      take(1)
    ).subscribe();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.ready && request.url.startsWith(this.baseUri)) {
      return next.handle(request).pipe(
        catchError(error => {
          if (error instanceof Studio3ApiError) {
            throw error;
          }
          throw new Studio3ApiError(error);
        })
      );
    }
    return next.handle(request);
  }
}
