import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangService } from 'src/app/lang/lang.service';

@Component({
  selector: 'goco-landing-container',
  templateUrl: './landing-container.component.html',
  styleUrls: ['./landing-container.component.scss']
})
export class LandingContainerComponent implements OnInit {

  @Input() title: string;
  constructor(
    public langService: LangService,
    protected router: Router
  ) { }

  ngOnInit(): void {
  }

  switchLang(lang: string) {
    const isRoute = this.langService.langs.findIndex((l) => {
      return this.router.url.startsWith(`/${l}/`);
    }) > -1;
    if (isRoute){
      this.router.navigateByUrl('/' + lang + this.router.url.substr(3));
    } else {
      this.langService.setLang(lang);
    }

  }

  brandClick() {
    // TODO: Go to vendor website
  }
}
