export interface IPeriod {
  start: Date;
  end: Date;
}
export const PRESET_PERIODS = [
  'today', 'yesterday',
  'this_week', 'last_week',
  'this_month', 'last_month',
  'last_7_days', 'last_30_days'
];

export class SelectPeriod {
  static readonly type = '[Period] Select';
  constructor(public payload: string) { }
}

export class SetCustomPeriod {
  static readonly type = '[Period] Custom';
  constructor(public payload: {start: Date, end: Date}) { }
}

export class SetPeriodTimezone {
  static readonly type = '[Period] Timezone';
  constructor(public payload?: string){}
}



export class BuildPeriods {
  static readonly type = '[Period] Build';
}
