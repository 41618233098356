<ng-container *ngIf="address">
  <div class="address1">{{address.address1}}</div>
  <div class="address2" *ngIf="address.address2">{{address.address2}}</div>
  <div class="region" >
    <span class="city">{{address.city}}</span>
    <span class="province" *ngIf="address.province">{{
      'province.'+address.country+'.'+address.province | transloco
    }}</span>
    <span class="postalcode" *ngIf="address.postalcode">{{address.postalcode | uppercase}}</span>
  </div>
  <div class="country">{{'country.'+address.country | transloco}}</div>
</ng-container>